import Vue from "vue";
import Vuex from "vuex";
import jwt_decode from "jwt-decode";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    authorised: 0,
    user: null,
    accountFinance: null,
    token: null,
    expire: null,
    currentTime: null,
    taskScheduleSettings: {
      userCollapse: [],
    },
    showTemplates: false,
    sliderView: "",
    estimateCostTypeSliderView: "",
    popupDialog: "",
    selectedObject: null,
    selectedData: null,
    timer: null,
    timerCorrection: 0,
    isPaused: null,
    refreshData: false,
    sliderLoader: false,
    staticMenuInactive: false,
    templateToEstimate: null,
    deskOrder: {},
    jobsFilters: null,
    integrationId:null
  }
}

export default new Vuex.Store({
  state: getDefaultState(),
  getters: {
    isPaused(state) {
      return state.isPaused;
    },
    permissions(state) {
      return state.user.permission_id;
    },
    integrationId(state){
      return state.integrationId;
    },
    expire(state) {
      return state.expire;
    },
    templateToEstimate(state) {
      return state.templateToEstimate
    },
    timer(state) {
      return state.timer;
    },
    taskScheduleSettings(state) {
      return state.taskScheduleSettings;
    },
    isAuthenticated(state) {
      return state.token != null;
    },
    token(state) {
      return state.token;
    },
    sliderView(state) {
      return state.sliderView;
    },
    estimateCostTypeSliderView(state) {
      return state.estimateCostTypeSliderView
    },
    staticMenuInactive(state) {
      return state.staticMenuInactive;
    },
    showTemplates(state) {
      return state.showTemplates;
    },
    selectedObject(state) {
      return state.selectedObject;
    },
    selectedData(state) {
      return state.selectedData;
    },
    user(state) {
      return state.user;
    },
    popupDialog(state) {
      return state.popupDialog;
    },
    refreshData(state) {
      return state.refreshData;
    },
    sliderLoader(state) {
      return state.sliderLoader;
    },
    currentTimer(state) {
      if (!state.timer) return null;
      var s =
        state.timer.timer_end > 0
          ? state.timer.time_spent
          : state.currentTime -
          state.timer.timer_start +
          (state.timer.time_spent !== null
            ? parseInt(state.timer.time_spent)
            : 0);

      return s;
    },
    deskOrder(state) {
      return state.deskOrder
    },
    accountFinance(state) {
      return state.accountFinance
    },    
    jobsFilters(state) {
      return state.jobsFilters;
    },
  },
  mutations: {
    setRecentlySavedProfile(){
    },
    setSliderView(state, data) {
      state.sliderView = data;
    },
    setEstimateCostTypeSliderView(state, data) {
      state.estimateCostTypeSliderView = data;
    },
    setPopupDialog(state, data) {
      state.popupDialog = data;
    },
    setShowTemplates(state, data) {
      state.showTemplates = data;
    },
    setSelectedObject(state, data) {
      state.selectedObject = data;
    },
    setSelectedData(state, data) {
      state.selectedData = data;
    },
    setTaskScheduleSettings(state, data) {
      state.taskScheduleSettings = data;
    },
    setToken(state, data) {
      state.user = data.user;
      state.token = data.jwt;
      var decoded = jwt_decode(data.jwt)
      state.expire = decoded.exp;
    },
    setEstimateToTemplate(state, data) {
      state.templateToEstimate = data
    },
    setTimer(state, data) {
      state.timer = data;
    },
    setIsPaused(state, data) {
      state.isPaused = data;
    },
    auth(state, status) {
      state.authorised = status;
    },
    user(state, uid) {
      state.user = uid;
    },
    logout(state) {
      Object.assign(state, getDefaultState())
    },
    setRefreshData(state, data) {
      state.refreshData = data;
    },
    setSliderLoader(state, data) {
      state.sliderLoader = data;
    },
    handleRefreshToken(state, data) {
      state.token = data;
    },
    toggleStaticMenuInactive(state) {
      state.staticMenuInactive = !state.staticMenuInactive;
    },
    setDeskOrder(state, data) {
      state.deskOrder[data.id] = data.order
    },
    setAccountFinance(state, data) {
      state.accountFinance = data
    },
    setProfilePicture(state, data) {
      state.user.profile_picture = data;
    },
    setUserPermissions(state, data) {
      state.user.permission_id = data.permission_id;
    },
    setJobsFilters(state, data) {
      state.jobsFilters = data;
    },
    setIntegrationId(state, data) {
      state.integrationId = data;
    },
  },
  actions: {
    setRecentlySavedProfile({ commit }){
      commit("setRecentlySavedProfile")
    },
    setProfilePicture({ commit }, data) {
      commit("setProfilePicture", data);
    },
    setIsPaused({ commit }, data) {
      commit("setIsPaused", data);
    },
    setSelectedObject({ commit }, data) {
      commit("setSelectedObject", data);
    },
    setShowTemplates({ commit }, data) {
      commit("setShowTemplates", data);
    },
    setSelectedData({ commit }, data) {
      commit("setSelectedData", data);
    },
    setSliderView({ commit }, data) {
      commit("setSliderView", data);
    },
    setEstimateCostTypeSliderView({ commit }, data) {
      commit('setEstimateCostTypeSliderView', data)
    },
    setPopupDialog({ commit }, data) {
      commit("setPopupDialog", data);
    },
    setTaskScheduleSettings({ commit }, data) {
      commit("setTaskScheduleSettings", data);
    },
    setCurrentTime({ commit }) {
      commit("setCurrentTime");
    },
    setEstimateToTemplate({ commit }, data) {
      commit("setEstimateToTemplate", data)
    },
    setTimer({ commit }, data) {
      commit("setTimer", data);
    },
    setRefreshData({ commit }, data) {
      commit("setRefreshData", data);
    },
    setSliderLoader({ commit }, data) {
      commit("setSliderLoader", data);
    },
    handleRefreshToken({ commit }, data) {
      commit("handleRefreshToken", data);
    },
    setDeskOrder({ commit }, data) {
      commit("setDeskOrder", data);
    },
    setAccountFinance({ commit }, data) {
      commit("setAccountFinance", data);
    },
    toggleStaticMenuInactive({ commit }, data) {
      commit("toggleStaticMenuInactive");
    },
    setIntegrationId({ commit }, data) {
      commit("setIntegrationId", data);
    },
    setToken({ commit }, data) {
      commit("setToken", data);
    },
    logout({ commit }) {
      commit("logout");
    },
    authenticate({ commit }, form) {
      return Vue.prototype.$axios
        .post(process.env.VUE_APP_ROOT_API + "/auth/login", form, {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          commit("setToken", response.data.data);
          return response;
        })
        .catch((error) => {
          throw error;
        });
    },
    
    setJobsFilters({ commit }, payload) {
      commit("setJobsFilters",  JSON.stringify(payload));
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
