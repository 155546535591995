<template>
  <div align="left">
    <!-- <div v-if="!job">
      <JobLoading />
    </div> -->
    <div v-if="job && job.is_template === 1" class="flex">
      <Chip class="mr-3 mb-3 tag-chip text-white bg-pink">
        <div class="flex p-2">Template</div>
      </Chip>
    </div>
    <div class="flex justify-content-between job">
      <div class="col flex p-3 job-description-align">
        <div
          class="cursor-pointer headerHover job hidden sm:flex"
          @click="goBack"
        >
          <Skeleton
            v-if="isLoading"
            height="24px"
            width="6rem"
            borderRadius="6px"
          />
          <h1 class="job-title" v-else-if="job && job.is_template == 0">
            Jobs<i
              style="color: #778ca2"
              class="las la-angle-right text-20 pt-1 pr-1"
            />
          </h1>
          <h1 v-else>Job Template</h1>
        </div>

        <h1 class="job-title" v-if="job">
          <div
            class="flex align-items-center justify-content-between sm:justify-content-start"
          >
            {{ job.title }}
            <span class="text-12 pl-4" v-if="jobsStatus && job">
              <!-- <span
                v-if="job?.accepted === 1"
                :style="getTagStyle('Accepted')"
                class="p-2"
                >Accepted</span
              > -->

              <span :style="getTagStyle(job?.status)" class="p-2">
                {{ toTitleCase(job?.status) }}</span
              >
            </span>
          </div>
          <div
            class="pb-1 pt-2 font-light flex"
            style="font-size: 14px"
            v-if="job && job.client && job.is_template === 0"
          >
            <i style="color: #4d7cfe" class="las la-city pr-2"></i>
            <span
              v-can-click="'admin'"
              class="cursor-pointer headerHover pl-1"
              @click="handleClick(job)"
            >
              {{ job.client.name }}
            </span>
            <i
              v-if="job.contact"
              style="color: #4d7cfe"
              class="las la-user-tie pl-2 pr-2"
            ></i
            >{{ job.contact }}
          </div>
        </h1>
      </div>
      <div
        v-if="job && job.is_template === 0"
        class="col-2 flex selected-job-align flex-row justify-content-end align-items-center flex-grow-0 text-right font-light"
      >
        <h1 class="job-title align-horizontally font-normal" v-if="job">
          {{ job.job_number }}
          <div class="edit-pen-align">
            <i
              class="las la-pen i-20 grey cursor-pointer pr-3"
              @click="
                job.copy = false;
                $store.dispatch('setSelectedObject', job);
                $store.dispatch('setSliderView', 'JobSlider');
              "
            />
            <i
              class="las la-ellipsis-h text-20 cursor-pointer"
              @click="toggle"
            />
          </div>
        </h1>
        <div class="flex pl-5">
          <template>
            <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
            <Dialog
              header="Enter Template Name"
              :visible.sync="displayDialog"
              :containerStyle="{ width: '350px' }"
              :modal="true"
            >
              <div class="confirmation-content">
                <InputText
                  class="w-full"
                  v-model="templateName"
                  placeHolder="Template Name"
                  @keydown.enter="saveAsTemplate"
                  @keydown.escape="closeDialog"
                />
              </div>
              <template #footer>
                <Button
                  label="Save"
                  icon="pi pi-check"
                  @click="saveAsTemplate"
                  class="p-button-text"
                />
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  @click="closeDialog"
                  class="p-button-text"
                  autofocus
                />
              </template>
            </Dialog>
          </template>
        </div>
      </div>

      <!-- <div v-if="job?.accepted === 0" class="flex align-items-center pl-3">
        <div>
          <Button
            label="Accept Job"
            @click="
              $store.dispatch('setSelectedObject', job);
              $store.dispatch('setSliderView', 'AcceptJobSlider');
            "
          />
        </div>
      </div> -->
    </div>
    <!-- <div class="hidden sm:flex"> -->
    <template v-if="activeIndex != null">
      <TabView
        @tab-change="handleTabChange"
        class="p-tab-show-panel mb-3 sticky-tabs"
        v-bind:activeIndex.sync="activeIndex"
        @tab-click="handleTabClick"
      >
        <TabPanel v-for="(tab, index) in tabs" :key="index" :header="tab.name">
          <JobDetails
            :job="job"
            v-if="
              $route.name === 'job--tasks' || $route.name === 'job--costing'
            "
          ></JobDetails>
          <Invoice
            v-if="$route.name === 'job--financial' && activeIndex === index"
          />
          <JobEstimate
            v-else-if="$route.name === 'job--estimate' && activeIndex === index"
          />
          <component :is="tab.component" v-else-if="activeIndex === index" />
        </TabPanel> </TabView
    ></template>
  </div>
  <!-- </div> -->
</template>

<script>
import {
  state,
  fetchJob,
  fetchJobsStatus,
} from "../../../services/data_service.js";
import JobTasks2 from "@/application/Job/components/JobTasks2";
import JobDetails from "@/application/Job/components/JobDetails";
import JobCosts from "@/application/Job/components/JobCosts";
import JobCosting2 from "@/application/Job/components/JobCosting2.vue";
import Invoices from "@/application/Finance/views/Invoices.vue";
import Invoice from "@/application/Finance/views/Invoice.vue";
import JobNotesAndDocuments from "@/application/Job/components/JobNotesAndDocuments.vue";
import JobEstimates from "@/application/Job/components/JobEstimates.vue";
import JobEstimate from "@/application/Job/components/JobEstimate.vue";

export default {
  data() {
    return {
      mounted: false,
      tabs: [
        { name: "Tasks", component: JobTasks2, permission: null }, // No specific permission required
        { name: "Costs", component: JobCosts, permission: null }, // No specific permission required
        { name: "Job Costing", component: JobCosting2, permission: "admin" }, // Requires 'admin' permission
        { name: "Estimates", component: JobEstimates, permission: "admin" }, // Requires 'admin' permission for this example
        { name: "Financial", component: Invoices, permission: "admin" }, // Requires 'admin' permission for this example
        {
          name: "Messages & Docs",
          component: JobNotesAndDocuments,
          permission: null,
        }, // No specific permission required
      ],
      activeIndex: null,
      errors: [],
      isLoading: true,
      showTimeline: false,
      invoiceUid: false,
      displayDialog: false,
      Job: "",
      sliderValue: null,
      templateName: null,
      clientContact: "Default Contact",
      items: [],
      jobTemplate: {
        name: null,
      },
      customEvents: [],
    };
  },
  beforeCreate() {
    if (this.$route.name == "job") {
      this.$router.replace({ name: "job--tasks" });
    }
  },
  beforeDestroy() {
    this.$pusher.unsubscribe(this.account_uid);
    this.$bus.off("refreshData", this.loadData);
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  methods: {
    handleClick(job) {
      if (!this.canView("admin")) {
        return;
      }
      this.$router.push("/client/" + job.client.uid);
    },
    getTagStyle(status) {
      if (status === "Overdue") {
        return "border-radius: 6px; background: #FFF1F7 !important;color: #FE4D97 !important;";
      }
      if (status == "draft" || status == "closed") {
        return "border-radius: 6px; background: rgb(232, 236, 239); !important; color: #778ca2 !important; ";
      }
      return "border-radius: 6px; background: #f4fbef !important;color: #6dd230 !important;";
    },

    toggle(event) {
      this.$refs.menu.toggle(event);
    },

    closeDialog() {
      this.displayDialog = false;
    },
    goBack() {
      if (this.job.is_template === 1 && this.job) {
        this.$router.push("/settings/templates");
        //this.$store.dispatch("setShowTemplates", true);
      } else if (this.job.is_template === 0 && this.job) {
        this.$router.push("/jobs");
      }
    },
    getProgressStyle(budget) {
      var progress = budget
        ? ((budget.spend / budget.amount) * 100).toFixed(0)
        : 0;
      if (Number(progress) >= 100) {
        return "background: #fe4d97;width:100%";
      } else {
        return "background: #6dd230;width:" + Number(progress) + "%";
      }
    },
    subscribeChannel() {
      var channel = this.$pusher.subscribe(this.account_uid);

      channel.bind("job", (data) => {
        if (this.sliderView !== "JobSlider" && data.uid === this.job.uid) {
          this.loadData();
        }
      });
    },
    async loadData() {
      await Promise.all([fetchJob(this.$route.params.uid), fetchJobsStatus()]);
    },

    saveAsTemplate() {
      var url = "/v1/jobs/template";
      if (this.job) {
        url = url + "/" + this.job.uid;
      }
      if (this.templateName) {
        this.jobTemplate.name = this.templateName;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API + url,
            JSON.stringify(this.jobTemplate),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.displayDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Template Saved",
              life: 3000,
            });
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Template name not entered",
          life: 3000,
        });
      }
    },
    handleTabChange(ev) {
      if (ev.index === 0) {
        this.$router.push({ name: "job--tasks" });
      }
      if (ev.index === 1) {
        this.$router.push({ name: "job--costs" });
      }
      if (ev.index === 2) {
        this.$router.push({ name: "job--costing" });
      }
      if (ev.index === 3) {
        this.$router.push({ name: "job--estimates" });
      }
      if (ev.index === 4) {
        this.$router.push({ name: "job--financials" });
      }
      if (ev.index === 5) {
        this.$router.push({ name: "job--documents" });
      }
    },
    hideShowTabs() {
      var tabs = document.getElementsByClassName("p-tabview-nav-link");
      for (let index = 0; index < tabs.length; index++) {
        const element = tabs[index];
        if (element.textContent === "Tasks") {
          if (this.job?.metrics?.active_tasks < 1) {
            element.style.display = "none";
          } else {
            element.style.display = "";
          }
        }
        if (element.textContent === "Costs") {
          if (this.job?.metrics?.active_costs < 1) {
            element.style.display = "none";
          } else {
            element.style.display = "";
          }
        }
      }

      if (this.activeIndex === 0 && this.job?.metrics?.active_tasks < 1) {
        this.$router.replace({ name: "job--costing" });
        this.activeIndex = 2;
      }
      if (this.activeIndex === 1 && this.job?.metrics?.active_costs < 1) {
        this.$router.replace({ name: "job--costing" });
        this.activeIndex = 2;
      }
    },
    handleTabClick(ev) {
      if (ev.index === 4) {
        if (this.$route.name === "job--financial") {
          this.$router.push({ name: "job--financials" });
        }
      }
      if (ev.index === 3) {
        if (this.$route.name === "job--estimate") {
          this.$router.push({ name: "job--estimates" });
        }
      }
    },
  },
  watch: {
    "job.metrics"(n, o) {
      this.hideShowTabs();
    },
    $route(to, from) {
      if (to.name === "jobs--tasks") {
        this.activeIndex = 0;
      }
      if (to.name === "jobs--costs") {
        this.activeIndex = 1;
      }
      if (to.name === "jobs--costing") {
        this.activeIndex = 2;
      }
      if (to.name === "jobs--estimates") {
        this.activeIndex = 3;
      }
      if (to.name === "jobs--financial") {
        this.activeIndex = 4;
      }
      if (to.name === "jobs--documents") {
        this.activeIndex = 5;
      }
    },
  },
  computed: {
    jobsStatus() {
      return state.jobsStatus;
    },
    sliderView() {
      return this.$store.getters.sliderView;
    },
    user() {
      return this.$store.getters.user;
    },
    account_uid() {
      return this.user?.account_uid;
    },
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    job() {
      return state.job;
    },

    jobTitle() {
      return this.job ? this.job.title : "";
    },
  },
  async mounted() {
    if (this.$route.name === "job--tasks") {
      this.activeIndex = 0;
    }
    if (this.$route.name === "job--costs") {
      this.activeIndex = 1;
    }
    if (this.$route.name === "job--costing") {
      this.activeIndex = 2;
    }
    if (
      this.$route.name === "job--estimates" ||
      this.$route.name === "job--estimate"
    ) {
      this.activeIndex = 3;
    }
    if (
      this.$route.name === "job--financials" ||
      this.$route.name === "job--financial"
    ) {
      this.activeIndex = 4;
    }
    if (this.$route.name === "job--documents") {
      this.activeIndex = 5;
    }

    this.subscribeChannel();
    state.job = null;

    var tabs = this.tabs;
    for (let i = tabs.length - 1; i >= 0; i--) {
      const tab = tabs[i];
      if (tab.permission) {
        if (!this.canView(tab.permission)) {
          tabs.splice(i, 1);
        }
      }
    }

    this.tabs = tabs;

    await this.loadData();

    if (this.job?.accepted === 1) {
      this.items.push({
        label: "Copy Job",
        command: () => {
          this.job.copy = true;
          this.$store.dispatch("setSelectedObject", this.job);
          this.$store.dispatch("setSliderView", "JobSlider");
        },
      });
    }
    if (this.job?.accepted === 0) {
      this.items.push({
        label: "Copy Job",
        command: () => {
          this.job.copy = true;
          this.$store.dispatch("setSelectedObject", this.job);
          this.$store.dispatch("setSliderView", "JobSlider");
        },
      });
      // this.items.push({
      //   label: "Accept Job",
      //   command: () => {
      //     this.$store.dispatch("setSelectedObject", this.job);
      //     this.$store.dispatch("setSliderView", "AcceptJobSlider");
      //   },
      // });
    }

    this.isLoading = false;

    this.mounted = true;
  },

  name: "Home",
  components: {
    // JobLoading,
    JobTasks2,
    JobCosts,
    JobCosting2,
    Invoices,
    JobDetails,
    Invoice,
    JobNotesAndDocuments,
    JobEstimates,
    JobEstimate,
    // JobTasks,
  },

  metaInfo() {
    return {
      title: this.jobTitle,
      meta: [{ vmid: "description", name: "description", content: "" }],
    };
  },
};
</script>
<style>
.bg-tint {
  background-color: #f3f6ff;
}
.p-timeline .p-timeline-event-connector {
  background-color: #848688;
  height: 1px !important;
}
.p-tab-show-panel > .p-tabview-panels {
  display: unset !important;
}

.headerHover:hover {
  color: #4d7cfe !important;
}
.custom .p-timeline-event-opposite {
  padding: 0 !important;
}
.custom .p-timeline-event-content div {
  display: flex;
  width: 150px;
  z-index: 1;
  right: -37px;
  position: absolute;
  top: 7px;
}
.p-timeline-event-content {
  display: flex;
  align-items: center;
}
.custom .p-timeline-event-content {
  padding: 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 0 !important;
}

.flex-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: space-between; /* Add space around the child elements */
}

.edit-pen-align {
  display: flex;
  margin: 5px 5px 5px 8px;
}

.job-title.align-horizontally {
  display: grid;
  margin-left: 1rem !important;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (min-width: 100px) and (max-width: 500px) {
  .flex.justify-content-between.job {
    display: block !important;
  }
  .col-2.flex.selected-job-align {
    display: block !important;
  }
  .col.flex.job-description-align {
    display: block !important;
  }
}

@media only screen and (max-width: 715px) {
  .flex.justify-content-between.job {
    font-size: 16px !important;
    /* display: block !important; */
  }
  .col-2.flex.selected-job-align {
    font-size: 16px !important;
    /* display: block !important; */
  }
  .col.flex.job-description-align {
    font-size: 16px !important;
    /* display: block !important; */
  }
  .job-title {
    font-size: 16px !important;
  }
}
</style>