var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card pt-2"},[_c('div',{staticClass:"flex flex-wrap sm:justify-content-between justify-content-center align-items-center pb-2"},[_vm._m(0),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mr-3"},[_c('FilterSearchButton',{model:{value:(_vm.filters['global'].value),callback:function ($$v) {_vm.$set(_vm.filters['global'], "value", $$v)},expression:"filters['global'].value"}})],1),_c('div',{staticClass:"mr-2"},[_c('Button',{attrs:{"label":"Invite User","icon":"las la-user-plus"},on:{"click":function($event){_vm.display = true}}})],1)])]),_c('div',{staticClass:"flex flex-wrap justify-content-between align-items-center pb-2"},[_c('Dropdown',{staticClass:"filterDD",attrs:{"optionValue":"department_name","showClear":true,"filter":true,"optionLabel":"department_name","placeholder":"All Departments","options":_vm.departments},on:{"change":_vm.handleDepartmentFilterChange},scopedSlots:_vm._u([{key:"indicator",fn:function(){return [_c('i',{staticClass:"la la-filter"})]},proxy:true}]),model:{value:(_vm.departmentFilterName),callback:function ($$v) {_vm.departmentFilterName=$$v},expression:"departmentFilterName"}}),_c('div',{staticClass:"flex col p-0"},_vm._l((_vm.userData),function(user){return _c('div',{key:user.header},[(user.data.length > 0)?_c('div',{staticClass:"flex align-items-center font-light cursor-pointer pr-4",class:_vm.isButtonPressed === user.header
              ? 'bottom-border-positive'
              : 'bottom-border-invisible'},[_c('div',[_c('Button',{staticClass:"p-button-text p-button-link p-button-secondary",attrs:{"label":user.header},on:{"click":function($event){return _vm.filtered(user)}}})],1),(user.data.length > 0)?_c('Badge',{staticClass:"ml-2",style:(user.style),attrs:{"value":user.data.length}}):_vm._e()],1):_vm._e()])}),0),_c('div',{staticClass:"flex font-light",staticStyle:{"font-size":"smaller"}},[_vm._v(" You are on the Pro Plan. 5 - 10 Team Members. ")])],1),_c('DataTable',{attrs:{"responsiveLayout":"scroll","value":_vm.combinedUsers,"rowClass":_vm.rowClass,"filters":_vm.filters,"editMode":"row","dataKey":"uid"},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(!_vm.filters['global'].value)?_c('Button',{staticClass:"p-button-text p-button-secondary",attrs:{"label":"Invite User","icon":"las la-user-plus"},on:{"click":function($event){_vm.display = true}}}):_c('div',[_vm._v("No Results")])]},proxy:true}])},[_c('Column',{attrs:{"field":"name","header":"User","filterField":"name","styles":{ width: '20%' }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"p-column-title"},[_vm._v(" "+_vm._s(_vm.combineUserData.name)+" ")])]},proxy:true},{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"flex align-items-center"},[_c('div',{staticClass:"mr-3"},[(_vm.isLoading)?_c('div',[_c('Skeleton',{attrs:{"shape":"circle","width":"3rem","height":"3rem"}})],1):_c('Avatar',{style:(!slotProps.data.profile_picture
                  ? {
                      'background-color': _vm.getColorFromName(slotProps.data),
                      color: '#ffffff',
                    }
                  : ''),attrs:{"image":slotProps.data.profile_picture,"label":!slotProps.data.profile_picture
                  ? _vm.getIntials(slotProps.data)
                  : null,"size":"large","shape":"circle"}})],1),(_vm.isLoading)?_c('Skeleton',{attrs:{"width":"40%"}}):[_c('div',{on:{"click":function($event){_vm.$store.dispatch('setSelectedObject', slotProps.data.uid);
                _vm.$store.dispatch('setSliderView', 'UserSlider');}}},[_vm._v(" "+_vm._s(slotProps.data["name"])+" "+_vm._s(slotProps.data["surname"])+" ")])]],2)]}}])}),_c('Column',{attrs:{"header":"Department","field":"primary_department","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton',{attrs:{"width":"50%"}}):_vm._l((_vm.getDepartmentNames(slotProps.data)),function(o,index){return _c('span',{key:index,staticClass:"p-2 mr-2 font-light",staticStyle:{"background":"rgb(232, 236, 239) !important","border-radius":"12px"}},[_vm._v(" "+_vm._s(o.department_name)+" ")])})]}}])}),_c('Column',{attrs:{"header":"Permission","field":"permissions"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton',{attrs:{"width":"40%"}}):[_vm._v(" "+_vm._s(_vm.showPermissionName(slotProps.data))+" ")]]}}])}),_c('Column',{attrs:{"header":"User Type","field":"userType"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton',{attrs:{"width":"40%"}}):[_vm._v(" "+_vm._s(slotProps.data["userType"])+" ")]]}}])}),_c('Column',{attrs:{"field":"active","header":"Status","bodyStyle":"transform: translateY(6px);"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton',{attrs:{"width":"4rem","height":"2rem","borderRadius":"16px"}}):[_c('AActiveSwitch',{on:{"change":function($event){return _vm.saveRow(slotProps.data)}},model:{value:(slotProps.data[slotProps.column.field]),callback:function ($$v) {_vm.$set(slotProps.data, slotProps.column.field, $$v)},expression:"slotProps.data[slotProps.column.field]"}})]]}}])})],1),_c('InviteUser',{attrs:{"display":_vm.display},on:{"update:display":function($event){_vm.display=$event},"dismiss":function($event){_vm.display = false}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"mr-3 text-primary cursor-pointer"},[_vm._v("Team")]),_c('div',{staticClass:"font-light cursor-pointer"},[_vm._v("Invitations")])])
}]

export { render, staticRenderFns }