<template>
  <div class="flex align-items-center">
    <div class="col flex-grow-0">
      <div
        v-if="!file.url.match(/.(jpg|jpeg|png|gif)$/i) ? null : file.url"
        class="p-avatar p-component p-avatar-image p-avatar-circle p-avatar-lg"
      >
        <img
          v-img="{
            src: file.preview
              ? file.preview
              : file.thumb
              ? file.thumb
              : file.url,
          }"
          :src="file.thumb ? file.thumb : file.url"
        />
      </div>
      <Avatar
        v-else
        size="large"
        :style="{
          'background-color': '#F2F4F6',
          color: '#B9C5D0',
        }"
        shape="circle"
        :icon="!file.url.match(/.(jpg|jpeg|png|gif)$/i) ? 'las la-file' : null"
      />
    </div>
    <div class="col flex-wrap">
      <div class="col-12 p-0">{{ file.name }}</div>
      <div class="col-12 p-0 flex">
        <div class="col p-0">
          <div class="font-light text-xs">
            {{ formatDateTime(created_date) }}
          </div>
          <div class="font-light text-xs">{{ file.size }}Kb</div>
        </div>
        <div class="col-grow-0 p-0">
          <i
            class="las la-trash i-20 cursor-pointer pr-2 grey"
            @click.stop="
              deletePrompt(
                file.name,
                '/v1/tasks/upload/delete?attachment=' + file.uid
              )
            "
          ></i>
          <!-- <i classs="las la-share cursor-pointer i-20 grey pr-2" /> -->
          <a :href="file.url">
            <i class="las la-download cursor-pointer i-20 grey"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Attachment",
  props: {
    file: Object,
  },
  data() {
    return {
      preview: false,
    };
  },
  methods: {
    formatDateTime(dateTimeString) {
      const dateTime = new Date(dateTimeString);

      const day = dateTime.getDate().toString().padStart(2, "0");
      const month = dateTime.toLocaleString("default", { month: "short" });
      const year = dateTime.getFullYear().toString().slice(-2);
      const optionsTime = {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      };
      const formattedDate = `${day} ${month} ${year}`;
      const formattedTime = dateTime.toLocaleTimeString("en-US", optionsTime);
      return `${formattedDate} | ${formattedTime}`;
    },
    // deleteAttachment() {
    //   this.$confirm.require({
    //     message: "Are you sure you want to delete this attachment?",
    //     header: "Confirm Delete",
    //     icon: "pi pi-exclamation-triangle",
    //     accept: () => {
    //       this.$axios
    //         .delete(process.env.VUE_APP_ROOT_API + "/v1/tasks/upload/delete", {
    //           params: {
    //             attachment: this.file.uid,
    //           },
    //         })
    //         .then((response) => {
    //           this.$emit("refresh");
    //         })
    //         .catch((error) => {
    //           console.error(error);
    //         });
    //     },
    //     reject: () => {
    //       //callback to execute when user rejects the action
    //     },
    //   });
    // },
    handleClick(file) {
      alert(file);
    },
  },
  computed: {
    created_date: function () {
      var date = new Date(this.file.created_date);
      return date.toDateString().slice(3, 15);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>