<template>
  <div class="card pt-3">
    <div class="flex justify-content-between pb-2 border-bottom-only mb-2">
      <div class="col">
        <div class="cursor-pointer headerHover">
          <h1>Estimates</h1>
        </div>
      </div>
      <div class="col flex justify-content-end">
        <div class="pl-1 pr-1" v-if="$route.params?.uid">
          <Button
            label="New Estimate"
            icon="las la-plus"
            @click="estimateDialog = !estimateDialog"
            :disabled="
              jobItems?.filter((g) => g.tasks.length > 0).length < 1 &&
              jobItems?.filter((g) => g.costs.length > 0).length < 1
            "
          />
        </div>
      </div>
    </div>

    <DataTable
      responsiveLayout="scroll"
      :value="jobEstimate"
      :rowClass="rowClass"
      editMode="row"
      @row-click="openEstimate"
      dataKey="uid"
    >
      <template #empty>
        <Button
          label="New Cost Estimate"
          class="p-button-text p-button-secondary"
          @click="estimateDialog = !estimateDialog"
          :disabled="
            jobItems?.filter((g) => g.tasks.length > 0).length < 1 &&
            jobItems?.filter((g) => g.costs.length > 0).length < 1
          "
          icon="las la-plus"
      /></template>
      <Column field="document_number" header="CE">
        <template #body="slotProps">
          <Skeleton width="35%" v-if="isLoading"></Skeleton>
          <span v-else class="cursor-pointer">
            {{ slotProps.data[slotProps.column.field] }}
          </span>
        </template>
      </Column>
      <Column field="name" header="CE Name">
        <template #body="slotProps">
          <Skeleton width="35%" v-if="isLoading"></Skeleton>
          <span v-else class="cursor-pointer">
            {{ slotProps.data[slotProps.column.field] }}
          </span>
        </template>
      </Column>
      <Column field="amount" header="Amount">
        <template #body="slotProps">
          <Skeleton width="35%" v-if="isLoading"></Skeleton>
          <span v-else class="cursor-pointer">
            {{
              formatCurrency(
                slotProps.data[slotProps.column.field] * (1 + taxRate / 100)
              )
            }}
          </span>
        </template>
      </Column>
      <Column field="date_due" header="Valid Until">
        <template #body="slotProps">
          <Skeleton width="35%" v-if="isLoading"></Skeleton>
          <span v-else class="cursor-pointer">
            {{ slotProps.data[slotProps.column.field] }}
            <!-- {{ formatCurrency(slotProps.data[slotProps.column.field]) }} -->
          </span>
        </template>
      </Column>

      <Column
        :footerStyle="{
          backgroundColor: 'white',
        }"
        field="active"
      >
        <template #header
          ><span class="p-column-title ml-1">Status</span></template
        >
        <template #body="slotProps">
          <Skeleton v-if="isLoading"></Skeleton>
          <div v-else>
            <div class="col ce-co pl-0">
              <div class="text-12">
                <span
                  v-if="slotProps.data.status == 'approved'"
                  :style="'background: #F4FBEF !important;borderRadius: 6px;color: #6dd230'"
                  class="p-2"
                  >{{ "Approved" }}</span
                >
                <span
                  v-if="!slotProps.data.status"
                  :style="'background: #E8ECEF !important;borderRadius: 6px;'"
                  class="p-2"
                  >{{ "Draft" }}</span
                >
              </div>
            </div>
          </div>
        </template>
      </Column>
      <Column
        bodyStyle="text-align:right"
        :styles="{ width: '4%' }"
        :footerStyle="{
          backgroundColor: 'white',
        }"
      >
        <template #body="slotProps">
          <Skeleton v-if="isLoading"></Skeleton>

          <div
            v-else
            class="flex justify-content-end align-items-center cursor-pointer invisible"
          >
            <i
              v-if="!downloading"
              class="las la-cloud-download-alt grey i-20 mr-1"
              @click.stop="getPdf(slotProps.data.uid)"
            />
            <ProgressSpinner
              class="p-12 h-1rem w-1rem p-0 mr-2"
              style="width: 10px; height: 10px"
              v-else
            />
            <i
              class="las la-trash grey i-20"
              @click.stop="
                deletePrompt(
                  slotProps.data.document_number,
                  '/v1/finance/invoice/delete/' + slotProps.data.uid,
                  'get'
                )
              "
            />
          </div>
        </template>
      </Column>
    </DataTable>

    <Dialog
      header="New Cost Estimate"
      contentStyle="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;max-width:1400px;min-height:500px"
      :visible.sync="estimateDialog"
      :modal="true"
      :closable="true"
      :dismissableMask="true"
    >
      <div class="formgrid p-fluid grid mb-2 mt-1">
        <div class="field col-12 sm:col-4">
          <label for="name">Cost Estimate Name</label>
          <div class="p-inputgroup">
            <InputText
              type="text"
              v-model="ceName"
              class="p-column-filter"
              placeholder="Cost Estimate Name"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-4">
          <label for="name">Valid Until</label>
          <div class="p-inputgroup">
            <Calendar
              id="icon"
              autocomplete="off"
              v-model="validUntil"
              :showIcon="true"
              :selectOtherMonths="true"
              :showOtherMonths="true"
              dateFormat="yy-mm-dd"
              placeholder="Valid Until"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-4">
          <label for="name">Payment Terms</label>
          <div class="p-inputgroup">
            <Dropdown
              placeHolder="Payment Terms"
              :options="termOptions"
              v-model="terms"
            />
          </div>
        </div>
        <div class="field col-12">
          <label for="name">Description</label>

          <Editor
            ref="editor"
            placeholder="Description"
            id="Description"
            :autoResize="true"
            v-model="ceDescription"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-align"></button>
                <button class="ql-link"></button>
              </span> </template
          ></Editor>
        </div>
      </div>
      <template v-if="jobItems">
        <InvoiceTaskCost
          :groups="jobItems"
          @dismiss="estimateDialog = false"
          selectAllDefault
          :jobId="jobId"
          :dismissableMask="true"
          :closable="true"
          :validUntil="validUntil"
          :ceName="ceName"
          :ceDescription="ceDescription"
          :terms="terms"
          hideSelectAll
          isEstimate
        ></InvoiceTaskCost
      ></template>
    </Dialog>
  </div>
</template>
<script>
import {
  state,
  fetchJobEstimate,
  fetchJobItems,
} from "../../../services/data_service.js";
import InvoiceTaskCost from "@/application/Finance/components/InvoiceTaskCost.vue";
import { FilterMatchMode } from "primevue/api";
export default {
  components: {
    InvoiceTaskCost,
  },
  data() {
    return {
      downloading: false,
      estimateDialog: false,
      isLoading: false,
      ceName: null,
      ceDescription: null,
      terms: "30 days",
      termOptions: ["14 days", "30 days", "60 days"],
      validUntil: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    await this.loadData();
    this.setValidUntil();
  },
  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    setValidUntil() {
      const currentDate = new Date();

      const validUntilDate = new Date();
      validUntilDate.setDate(currentDate.getDate() + 30);

      this.validUntil = validUntilDate.toISOString().slice(0, 10);
    },
    addDays(date, days) {
      const newDate = new Date(date);
      const numDays = parseInt(days.split(" ")[0]);
      newDate.setDate(newDate.getDate() + numDays);
      return newDate;
    },
    rowClass() {
      return "row-accessories row-accessories2";
    },
    formatDate(date) {
      return this.$moment(date).format("DD MMM YY");
    },
    openEstimate(ev) {
      this.$router.push({
        name: "job--estimate",
        params: { estimate_uid: ev.data.uid },
      });
    },
    async loadData() {
      if (!state.jobEstimate) {
        this.isLoading = true;
        state.jobEstimate = [];
        for (let index = 0; index < 5; index++) {
          state.jobEstimate.push({});
        }
      }
      await Promise.all([
        fetchJobItems(this.$route.params.uid),
        fetchJobEstimate(this.$route.params.uid),
      ]);

      if (this.job?.description) {
        this.ceDescription = this.job.description;
      }

      this.isLoading = false;
    },
    async getPdf(id) {
      this.downloading = true;
      await this.downloadPdf(
        process.env.VUE_APP_ROOT_API + `/v1/finance/download/${id}`
      );
      setTimeout(() => {
        this.downloading = false;
      }, 3000);
    },
  },
  computed: {
    jobEstimate() {
      if (!Array.isArray(state.jobEstimate)) {
        if (state.jobEstimate) {
          return [state.jobEstimate];
        }
      }
      return state.jobEstimate;
    },
    job() {
      return state.job;
    },
    jobItems() {
      return state.jobItems;
    },
    jobId() {
      return this.$route.params.uid;
    },
    jobItemTotals() {
      return state.jobItemTotals;
    },
    taxRate() {
      return this.jobItemTotals?.tax;
    },
  },
};
</script>