<template>
  <th class="header user-header resource-th">
    <div class="flex justify-content-between align-items-center">
      <div class="col-3 align-self-center flex justify-content-center">
        <Avatar
          :image="user.profile_picture"
          :label="!user.profile_picture ? getIntials(user) : null"
          :style="{
            'background-color': !user.profile_picture
              ? getColorFromName(user)
              : 'none',
            color: '#ffffff',
          }"
          size="large"
          shape="circle"
        />
      </div>
      <div
        class="col cursor-pointer flex flex-column justify-content-start"
        @click="expandToggle"
      >
        <div style="font-weight: normal" class="flex align-items-start">
          {{ user.name }}
        </div>
        <div
          v-if="workhours"
          class="font-light flex align-items-start"
          style="font-size: smaller"
        >
          {{ workhours }} hrs / week
        </div>
      </div>

      <div
        v-if="user.tasks.length > 0"
        class="align-self-center col-2 cursor-pointer"
        @click="expandToggle"
      >
        <i
          class="las la-sort-amount-down-alt"
          @click.stop="$emit('sortUser')"
        ></i>
        <i v-if="expand" class="las la-minus" />
        <i v-else class="las la-plus" />
      </div>
    </div>
  </th>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
    },
  },
  computed: {
    expand() {
      return !this.$store.getters.taskScheduleSettings.userCollapse.includes(
        this.user.uid
      );
    },
    workhours() {
      if (this.user.working_hours) {
        var ret = 0;
        for (var d in this.user.working_hours) {
          var element = this.user.working_hours[d];
          if (element.on) {
            var hoursMinutes = element.hours.split(":");
            var hours = parseInt(hoursMinutes[0], 10);
            var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
            ret += hours + minutes / 60;
          }
        }
        return ret;
      } else {
        return null;
      }
    },
  },
  methods: {
    expandToggle() {
      this.$emit("expandToggle");
    },
  },
};
</script>

<style>
.user-header {
  background-color: #f8fafb;
  vertical-align: middle;
  border-bottom: 1px solid rgb(232, 236, 239);
  border-right: 4px solid rgb(232, 236, 239);
}
</style>