<template>
  <div class="card col-12 pt-3">
    <div
      class="flex flex-wrap overflow-x-scroll justify-content-center sm:justify-content-between"
    >
      <div class="flex text-center">
        <div
          :class="
            'flex align-items-center p-3 mr-3 font-light cursor-pointer ' +
            (showClientsInArrears
              ? 'bottom-border-pink'
              : 'bottom-border-invisible')
          "
          @click="showClientsInArrears = !showClientsInArrears"
        >
          In Arrears
          <Badge
            v-if="!isLoading"
            :style="
              'backgroundColor: ' +
              (clientsInArrears.length > 0 ? '#fe4d97' : '#E8ECEF')
            "
            class="ml-2"
            :value="clientsInArrears.length"
          />
          <Badge v-else style="background-color: #e8ecef" class="ml-2" />
        </div>
      </div>
      <div class="flex text-center">
        <div class="mr-3">
          <FilterSearchButton v-model="filters['global'].value" />
        </div>
        <div class="mr-3">
          <Button
            label="New Client"
            icon="las la-plus"
            @click="$router.push('/client')"
          />
        </div>
      </div>
    </div>
    <DataTable
      :value="showClientsInArrears ? clientsInArrears : clients"
      responsiveLayout="scroll"
      :rowClass="rowClass"
      editMode="row"
      dataKey="uid"
      filterDisplay="menu"
      :filters.sync="filters"
      paginator
      :rows="30"
      :rowsPerPageOptions="[30, 50, 100]"
    >
      <template #empty>
        <Button
          label="New Client"
          class="p-button-text p-button-secondary"
          icon="las la-plus"
          @click="$router.push('/client')"
        />
      </template>
      <!-- Columns -->

      <Column
        sortable
        header="Code"
        field="client_code"
        filterField="client_code"
        :styles="{ width: '10%' }"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by client code"
          />
        </template>
        <template #body="slotProps">
          <Skeleton v-if="isLoading" width="50%"></Skeleton>
          <Skeleton class="mt-1" width="50%" v-if="isLoading"></Skeleton>
          <template v-else>
            <div @click="nav(slotProps)">
              <div class="font-light text-sm cursor-pointer">
                {{ slotProps.data.client_code }}
              </div>
            </div>
          </template>
        </template>
      </Column>
      <Column
        sortable
        header="Client"
        field="name"
        filterField="name"
        :styles="{ width: '20%' }"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by client name"
          />
        </template>
        <template #body="slotProps">
          <Skeleton v-if="isLoading" width="50%"></Skeleton>
          <Skeleton class="mt-1" width="50%" v-if="isLoading"></Skeleton>
          <template v-else>
            <div @click="nav(slotProps)">
              <div class="cursor-pointer">{{ slotProps.data["name"] }}</div>
            </div>
          </template>
        </template>
      </Column>
      <Column
        sortable
        filterField="account_executive"
        header="AE"
        field="account_executive"
        :styles="{ width: '20%' }"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by account executive"
          />
        </template>
        <template #body="slotProps">
          <Skeleton class="mt-1" width="50%" v-if="isLoading"></Skeleton>
          <template v-else>{{
            extractFirstName(slotProps.data["account_executive"])
          }}</template>
        </template>
      </Column>
      <Column
        sortable
        header="Active Jobs"
        field="active_jobs"
        :styles="{ width: '10%' }"
      >
        <template #body="slotProps">
          <Skeleton v-if="isLoading" width="10%"></Skeleton>
          <template v-else>{{ slotProps.data["active_jobs"] }}</template>
        </template>
      </Column>
      <Column
        sortable
        header="Billable"
        field="billable_amount"
        :styles="{ width: '15%' }"
      >
        <template #body="slotProps">
          <Skeleton v-if="isLoading" width="10%"></Skeleton>
          <template v-else>
            {{
              slotProps.data["billable_amount"] == null
                ? formatCurrency("100000")
                : formatCurrency(
                    slotProps.data["billable_amount"],
                    slotProps.data["currency"].description
                  )
            }}
          </template>
        </template>
      </Column>
      <Column
        sortable
        header="Credit Used"
        field="client_credit"
        :styles="{ width: '20%' }"
      >
        <template #body="slotProps">
          <template v-if="isLoading">
            <Skeleton width="30%"></Skeleton>
          </template>
          <template v-else>
            <div class="text-md">
              {{ getPercentage(slotProps.data) }} /
              {{
                formatCurrency(
                  Number(slotProps.data["client_credit"]),
                  slotProps.data["currency"].description
                )
              }}
            </div>
            <div class="flex align-items-center mt-1">
              <div
                class="surface-300 border-round overflow-hidden w-full"
                style="height: 6px"
              >
                <div class="h-full" :style="getProgressStyle(slotProps.data)" />
              </div>
            </div>
          </template>
        </template>
      </Column>
      <Column sortable header="Active" field="active">
        <template #body="slotProps">
          <Skeleton v-if="isLoading" width="40%"></Skeleton>
          <template v-else>
            <div class="flex">
              <AActiveSwitch
                :ref="slotProps.data.uid"
                @change="handleActive(slotProps.data)"
                v-model="slotProps.data[slotProps.column.field]"
              />
              <img
                v-if="slotProps.data.integration_id"
                src="../../../assets/xero/xero.svg"
                alt="Xero Icon"
                class="pl-2"
                style="height: 24px"
              />
            </div>
          </template>
        </template>
      </Column>
      <Column bodyStyle="text-align:right" :styles="{ width: '1rem' }">
        <template #body="slotProps">
          <div
            class="flex justify-content-end align-items-center invisible cursor-pointer"
          >
            <i @click.stop="nav(slotProps)" class="las la-pen i-20 grey pr-1" />
            <i
              @click="
                deletePrompt(
                  slotProps.data.name,
                  '/v1/clients/' + slotProps.data.uid
                )
              "
              class="las la-trash i-20 grey"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
  
  <script>
import { fetchClientsSummary, state } from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "ActiveClients",
  data() {
    return {
      filteredClient: null,
      showClientsInArrears: false,
      isLoading: true,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "account_executive.name": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        client_code: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    if (!state.clientsSummary) {
      state.clientsSummary = [{}, {}, {}, {}, {}, {}];
    }
    await this.loadData();
    this.isLoading = false;
  },
  methods: {
    async loadData() {
      await fetchClientsSummary();
    },
    handleActive(obj) {
      delete obj.account_executive;
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/clients/status/" + obj.uid,
          obj,
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: `${obj.company_name} ${
              obj.active == 1 ? "activated" : "deactivated"
            }`,
            life: 3000,
          });
        })
        .catch((error) => {
          this.$refs[obj.uid].inputVal = this.updateInputSwitch(
            this.$refs[obj.uid].value === 0 ? 1 : 0
          );
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            console.error(error);
          }
        });
    },
    getPercentage(val) {
      let percentage;
      let credit = Number(val.client_credit);
      if (credit == 0) {
        percentage = "0%";
        return percentage;
      }
      percentage = Math.round((val.billable_amount / credit) * 100) + "%";
      return percentage;
    },
    getProgressStyle(val) {
      if (val.billable_amount === null) return;
      if (val.client_credit == null || Number(val.client_credit) == 0) return;
      let progress =
        (Number(val.billable_amount) / Number(val.client_credit)) * 100;
      if (progress >= 100) {
        return "background: #fe4d97;width:100%";
      } else if (progress >= 60) {
        return "background: #ffab2b;width:" + progress + "%";
      } else {
        return "background: #6dd230;width:" + progress + "%";
      }
    },
    nav(ev) {
      this.$router.push("/client/" + ev.data.uid);
    },
    rowClass() {
      return "row-accessories row-accessories2";
    },
    extractFirstName(name) {
      if (name) {
        var names = name.split(" ");
        return names[0];
      }
    },
  },
  computed: {
    clientsInArrears() {
      let arrearsArray = [];
      if (this.clients != null) {
        for (let i = 0; i < this.clients.length; i++) {
          let percentage;
          let credit = Number(this.clients[i].client_credit);
          if (credit != 0) {
            percentage = Math.round(
              (this.clients[i].billable_amount / credit) * 100
            );
            if (percentage >= 100) {
              arrearsArray.push(this.clients[i]);
            }
          }
        }
      }
      return arrearsArray;
    },
    clients() {
      return state.clientsSummary;
    },
  },
};
</script>
  
  <style scoped>
/* Add any scoped styles here */
</style>
  