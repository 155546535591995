<template>
  <div class="card pt-3">
    <div
      class="flex flex-wrap overflow-x-scroll justify-content-center sm:justify-content-between pb-1"
    >
      <div class="flex text-center">
        <JobCostsFilter
          :invoiced="invoiced"
          :is_complete="is_complete"
          @filter="filter"
          :filters="costFilters"
        />
      </div>
      <div class="flex text-center">
        <div>
          <Button
            class="p-button-text p-button-secondary"
            v-if="isCollapse"
            label="Expand"
            @click="toggleShowDescripton"
            iconPos="right"
            icon="las la-expand"
          />

          <Button
            v-else
            class="p-button-text p-button-secondary"
            iconPos="right"
            label="Collapse"
            @click="toggleShowDescripton"
            icon="las la-compress"
          />
        </div>
        <div class="mr-3">
          <FilterSearchButton v-model="search" />
        </div>
        <!-- <div v-if="!addGroup && costs && costs.length > 0">
          <Button
            v-can="'admin'"
            label="New Group"
            @click="insertGroup()"
            icon="las la-plus"
          />
        </div>
        <div v-else-if="addGroup">
          <InputText
            ref="groupInput"
            v-model="newGroup"
            placeholder="Group Name"
            @keydown.enter="postGroup(newGroup)"
            @keydown.escape="
              newGroup = null;
              addGroup = false;
            "
          ></InputText>
          <Button
            icon="pi pi-check "
            :disabled="!newGroup"
            class="p-button-rounded grey-button ml-3"
            @click="postGroup(newGroup)"
          />
          <Button
            icon="pi pi-times "
            class="p-button-rounded grey-button ml-3"
            @click="
              newGroup = null;
              addGroup = false;
            "
          />
        </div> -->
      </div>
    </div>
    <div
      v-if="costs && costs.length < 1"
      class="flex justify-content-center sm:h-20rem align-items-center sm:p-6 text-16"
    >
      <div
        style="
          display: inline-block;
          padding: 30px 80px;
          background: #f8fafb;
          border-radius: 100px;
        "
      >
        <template v-if="!addGroup && costs.length === 0 && !isLoading">
          <a class="cursor-pointer" @click="insertGroup()">Create a group</a>
          to start adding costs.</template
        >
        <div v-else class="flex">
          <InputText
            ref="groupInput"
            v-model="newGroup"
            placeholder="Group Name"
            @blur="!newGroup ? (addGroup = false) : ''"
          ></InputText>
          <Button
            icon="pi pi-check "
            class="p-button-rounded grey-button ml-3"
            @click="postGroup(newGroup)"
          />
        </div>
      </div>
    </div>
    <draggable
      handle=".p-datatable-thead"
      :animation="140"
      :list="costs"
      @end="onEndGroup($event)"
      :disabled="!!editGroup"
    >
      <div
        v-for="u in costs"
        :key="u.uid"
        :style="
          minimized.indexOf(u.uid) > -1 || u.costs.length < 1
            ? 'margin-bottom:24px;'
            : 'margin-bottom:24px;'
        "
      >
        <div
          v-if="
            (hideCompletedCosts &&
              u.costs.filter((cost) => cost.is_complete == 0).length > 0) ||
            !hideCompletedCosts
          "
        >
          <div
            v-if="
              ((costFilters?.length > 0 || !!search) &&
                notFiltered(u.costs)?.length > 0) ||
              (costFilters?.length == 0 && !search)
            "
          >
            <div class="header-pointer" :id="u.uid" @click="handleHeaderClick">
              <DataTable
                v-if="
                  (hideCompletedCosts &&
                    u.costs.filter((cost) => cost.is_complete == 0).length >
                      0) ||
                  !hideCompletedCosts
                "
                :value="notFiltered(u.costs)"
                @row-reorder="onRowReorder(u, ...arguments)"
                responsiveLayout="scroll"
                :rowClass="rowClass"
                :name="'table-' + u.uid"
                class="dt-class transition-dt no-empty-div"
                editMode="row"
                dataKey="uid"
              >
                <Column
                  :rowReorder="true"
                  :headerStyle="{ width: '1rem' }"
                  :reorderableColumn="false"
                  ><template #header>
                    <i
                      v-if="minimized.indexOf(u.uid) > -1"
                      class="las la-plus cursor-pointer"
                    ></i>
                    <i v-else class="las la-minus cursor-pointer"></i>
                  </template>
                </Column>

                <Column
                  :styles="{ width: '65%' }"
                  field="description"
                  filterField="type_name"
                >
                  <template #header>
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div
                      v-else-if="editGroup !== u.uid"
                      class="font-light"
                      style="text-wrap: nowrap"
                    >
                      {{ u.title }}
                    </div>
                    <template v-else>
                      <InputText
                        ref="groupInput"
                        v-model="editGroupTitle"
                        placeholder="Group Name"
                        @blur="handleBlur"
                        @keydown.enter="updateGroup(editGroupTitle)"
                        @keydown.escape="
                          editGroupTitle = null;
                          editGroup = false;
                        "
                      ></InputText>
                      <Button
                        icon="pi pi-check "
                        :disabled="!editGroupTitle"
                        class="p-button-rounded grey-button-flat p-button-text ml-1"
                        @click.stop="updateGroup(editGroupTitle)"
                      />
                      <Button
                        icon="pi pi-times "
                        class="p-button-rounded grey-button-flat p-button-text ml-1"
                        @click.stop="
                          editGroupTitle = null;
                          editGroup = false;
                        "
                      />
                    </template>
                  </template>
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else @click="nav(slotProps.data)">
                      <!-- <div class="pb-1">
                    {{ slotProps.data.title }}
                  </div> -->

                      <div class="flex vertical-middle">
                        <div class="flex align-items-center cursor-pointer">
                          <i
                            style="font-size: 16px"
                            v-if="slotProps.data.is_complete == 1"
                            class="las la-check-circle grey mr-1 task-icon-complete"
                          />
                        </div>
                        <div
                          class="p-1 text-link"
                          v-if="slotProps.data.is_complete == 0"
                        >
                          <span
                            v-if="
                              slotProps.data.title != 'null' &&
                              slotProps.data.title != ''
                            "
                            class="cursor-pointer"
                            >{{ slotProps.data.type_name }} -
                            {{ slotProps.data.title }}</span
                          >
                          <span v-else>{{ slotProps.data.type_name }}</span>
                        </div>
                        <div
                          class="p-1 text-link"
                          v-if="slotProps.data.is_complete == 1"
                        >
                          <span class="flex align-items-center">
                            <span
                              v-if="
                                slotProps.data.title != 'null' &&
                                slotProps.data.title != ''
                              "
                              class="cursor-pointer completed-task-strike-text"
                              >{{ slotProps.data.type_name }} -
                              {{ slotProps.data.title }}</span
                            >
                            <span
                              class="cursor-pointer completed-task-strike-text"
                              v-else
                              >{{ slotProps.data.type_name }}</span
                            >
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="
                          checkShouldShowDescription() &&
                          slotProps.data.description
                        "
                        class="p-1 flex flex-wrap font-light"
                      >
                        <div
                          v-html="markDown(slotProps.data.description)"
                        ></div>
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  field="option"
                  :styles="{ padding: '0px' }"
                  :footerStyle="{
                    backgroundColor: 'white',
                  }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div
                      class="flex flex-column"
                      v-else-if="slotProps.data && slotProps.data.option"
                    >
                      <div class="pr-0 ce-co">
                        <!-- <span v-if="index > 0">Option</span> -->
                        <span style="width: 100%; display: inline-block">
                        </span>
                      </div>
                    </div>
                    <div v-else-if="slotProps.data" class="col pr-0"></div>
                  </template>
                </Column>
                <Column
                  :header="minimized.indexOf(u.uid) < 0 ? 'Qty' : ''"
                  field=""
                  :styles="{ width: '8%' }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else>
                      <div>
                        {{ formatAmount(slotProps.data.quantity) }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :header="minimized.indexOf(u.uid) < 0 ? 'Price/Unit' : ''"
                  field="option.price"
                  headerClass="header-right"
                  :styles="{ width: '8%' }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else class="flex flex-column">
                      <div class="col text-end pr-0 ce-co">
                        {{
                          formatCurrency(
                            slotProps.data.unit_price,
                            job?.client?.currency?.description
                          )
                        }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :header="minimized.indexOf(u.uid) < 0 ? 'Margin' : ''"
                  :styles="{ width: '8%' }"
                  headerClass="header-right"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else-if="slotProps.data.margin">
                      <div class="col text-end pr-0 ce-co">
                        {{
                          formatCurrency(
                            slotProps.data.margin,
                            job?.client?.currency?.description
                          )
                        }}
                      </div>
                    </div>
                    <div v-else class="col text-end pr-3">-</div>
                  </template>
                </Column>

                <Column
                  :headerStyle="{ textWrap: 'nowrap' }"
                  headerClass="header-right"
                  :header="
                    minimized.indexOf(u.uid) < 0
                      ? 'Total'
                      : u.costs.length +
                        ' cost' +
                        (u.costs.length === 1 ? '' : 's')
                  "
                  field="option.price"
                  :styles="{ width: '8%' }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else class="flex flex-column">
                      <div class="col text-end pr-0 ce-co">
                        {{
                          formatCurrency(
                            slotProps.data.price,
                            job?.client?.currency?.description
                          )
                        }}
                      </div>
                    </div>
                  </template>
                </Column>

                <Column
                  bodyStyle="text-align:right"
                  :styles="{ width: '1rem' }"
                >
                  <template #header>
                    <div
                      v-if="!editGroup && !isLoading"
                      class="flex justify-content-end align-items-center cursor-pointer"
                    >
                      <i
                        @click.stop="
                          editGroup = u.uid;
                          editGroupTitle = u.title;
                        "
                        class="las hover-show la-pen i-20 grey mr-1"
                      />
                      <i
                        @click.stop="
                          deletePrompt(u.title, '/v1/tasks/group/' + u.uid)
                        "
                        class="las la-trash i-20 grey hover-show"
                      />
                    </div>
                  </template>
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div
                      v-else
                      class="flex invisible justify-content-end align-items-center cursor-pointer"
                    >
                      <i
                        @click.stop="navEditMode(slotProps.data, 'edit')"
                        class="las la-pen i-20 grey mr-1"
                      />
                      <!-- <i
                      v-tooltip.bottom="{
                        value: 'Cost has tracked time',
                        disabled:
                          !slotProps.data.hours ||
                          slotProps.data.hours === '00:00',
                      }"
                      @click.stop="
                        slotProps.data.hours && slotProps.data.hours !== '00:00'
                          ? () => {}
                          : deletePrompt(
                              getCostTitle(slotProps.data),
                              '/v1/costs/' + slotProps.data.uid
                            )
                      "
                      :class="
                        'las la-trash i-20 ' +
                        (slotProps.data.hours &&
                        slotProps.data.hours !== '00:00'
                          ? 'disable-grey cursor-disable'
                          : 'grey')
                      "
                    /> -->
                    </div>
                  </template>
                </Column>

                <!-- <template #footer v-if="minimized.indexOf(u.uid) < 0">
                <Button
                  :key="u.uid"
                  label="Add Cost"
                  style="color: #b9c5d0"
                  class="p-button-text p-button-secondary ml-3"
                  @click.stop="
                    $store.dispatch('setSelectedObject', {
                      job: job,
                      group: u,
                    });
                    $store.dispatch('setSliderView', 'JobCostSlider');
                  "
                  icon="las la-plus"
                />
              </template> -->
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { fetchJobCosts, state } from "../../../services/data_service.js";
import { temp_state } from "../../../services/temp_state_service.js";
import { FilterMatchMode } from "primevue/api";
import JobCostsFilter from "@/application/Job/components/JobCostsFilter.vue";

export default {
  name: "Home",
  components: { draggable, JobCostsFilter },
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      costFilters: [],
      addGroup: null,
      isLoading: false,
      newGroup: null,
      editGroup: null,
      hideCompletedCosts: false,
      costTagUID: null,
      minimized: [],
      search: null,
      path: [
        {
          header: "Costs",
          icon: "la-check",
        },

        {
          header: "Notes",
          icon: "la-comments",
        },
        {
          header: "Files",
          icon: "la-folder-open",
        },
        {
          header: "Automations",
          icon: "la-robot",
        },
      ],
      isCollapse: false,
    };
  },
  async mounted() {
    temp_state.defaultJobApprove = true;
    await this.loadData();
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$pusher.unsubscribe(this.account_uid);
    this.$bus.off("refreshData", this.loadData);
  },
  methods: {
    markDown(text) {
      return text.replace(/\n/g, "<br>");
    },
    checkShouldShowDescription() {
      if (this.isCollapse) {
        return false;
      } else {
        return true;
      }
    },
    handleBlur() {
      setTimeout(() => {
        this.editGroup = false;
      }, 100);
    },
    toggleShowDescripton() {
      this.isCollapse = !this.isCollapse;
    },
    filter(f) {
      var i = this.costFilters.indexOf(f);
      if (i >= 0) {
        this.costFilters.splice(i, 1);
      } else {
        this.costFilters.push(f);
      }
    },
    notFiltered(arr) {
      var retArr = [];
      arr.forEach((i) => {
        if (this.costFilters?.length > 0) {
          if (this.costFilters.indexOf("invoiced") >= 0 && i.invoiced == 1) {
            retArr.push(i);
          } else if (
            this.costFilters.indexOf("is_complete") >= 0 &&
            i.is_complete == 1
          ) {
            retArr.push(i);
          }
        } else {
          retArr.push(i);
        }
      });

      if (this.search) {
        retArr = retArr.filter((i) => {
          return (
            i.task_type_name
              ?.toLowerCase()
              .includes(this.search.toLowerCase()) ||
            i.title?.toLowerCase().includes(this.search.toLowerCase()) ||
            i.type_name?.toLowerCase().includes(this.search.toLowerCase()) ||
            i.description?.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      }
      return retArr;
    },
    getTableStyle(u) {
      var table = document.getElementsByName("table-" + u.uid)[0];

      if (table && !table.original_height) {
        table.original_height = table?.getBoundingClientRect()?.height;
      }

      var style =
        "-webkit-transition: max-height 0.3s ease;  -moz-transition: max-height 0.3s ease;  transition: max-height 0.3s ease;";
      style +=
        this.minimized.indexOf(u.uid) > -1
          ? "max-height:42px;overflow: hidden;"
          : "max-height:" +
            (table?.original_height ? table.original_height + "px;" : "100%;");

      return style;
    },
    async loadData() {
      if (!state.jobCosts) {
        this.isLoading = true;
        state.jobCosts = [];
        for (let index = 0; index < 3; index++) {
          state.jobCosts.push({
            costs: [],
          });
          for (let index2 = 0; index2 < 3; index2++) {
            state.jobCosts[index].costs.push({});
          }
        }
      }
      await fetchJobCosts(this.$route.params.uid, 1);
      this.isLoading = false;
    },

    getCostTitle(obj) {
      if (obj.title != "null") {
        return obj.title;
      } else {
        return obj.type_name;
      }
    },
    expandTags() {
      temp_state.tagsExpanded = !temp_state.tagsExpanded;
    },

    getStyle(c) {
      if (c && c.charAt(0) !== "#") {
        c = "#" + c;
      }
      return "background-color: " + c + "; color:white;";
    },
    handleHeaderClick(ev) {
      var isHeader = false;
      var uid = null;
      if (ev && ev.composedPath) {
        const path = ev.composedPath();
        for (let element of path) {
          if (isHeader && uid) {
            break;
          }
          if (element.id) {
            uid = element.id;
          }
          if (element.tagName === "TH") {
            isHeader = true;
          }
          if (
            element.tagName === "TBODY" ||
            element.tagName === "INPUT" ||
            element.className === "p-datatable-footer"
          ) {
            break;
          }
        }
      }
      if (uid) {
        if (this.editGroup == uid) {
          return;
        }
        this.expand(uid);
      }
    },
    dateRange(job) {
      let date_start = new Date(job?.date_start?.date);
      let date_due = new Date(job?.date_due?.date);

      if (date_start.getFullYear() == date_due.getFullYear()) {
        return (
          this.$moment(job.date_start.nice, "DD MMM YYYY").format("DD MMM") +
          " - " +
          this.$moment(job.date_due.nice, "DD MMM YYYY").format("DD MMM 'YY")
        );
      }
      return (
        this.$moment(job.date_start.nice, "DD MMM YYYY").format("DD MMM 'YY") +
        " - " +
        this.$moment(job.date_due.nice, "DD MMM YYYY").format("DD MMM 'YY")
      );
    },
    getDateRangeClass(date) {
      switch (date.tag_class) {
        case "green":
          return "in-progress-job";
        case "red":
          return "overdue-job";
        case "orange":
          return "tomorrow-job";
      }
      return "in-progress-job";
    },
    insertGroup() {
      this.addGroup = !this.addGroup;
      this.$nextTick((r) => {
        this.$refs.groupInput.$el.focus();
      });
    },
    setitemGroup(uid) {
      temp_state.defaultJobApprove = true;
      state.itemGroup = uid;
    },
    getHeaderStyle(route) {
      if (route === "Costs") {
        return "font-weight: bold; color: #4D7CFE";
      }
    },
    unsetCost() {
      this.$store.dispatch("setSelectedObject", null);
      state.cost = null;
      state.amendments = [];
      state.costUsers = [];
    },
    getUsers(cost) {
      this.$store.dispatch("setSelectedObject", { uid: cost.uid });
      this.$store.dispatch("setPopupDialog", "UserSelect");
    },
    editDelete(u) {},
    onColReorder() {
      this.$toast.add({
        severity: "success",
        summary: "Column Reordered",
        life: 3000,
      });
    },

    rowClass(data) {
      return "row-accessories row-accessories2 no-padding-row";
    },
    getProgressStyle(cost) {
      var progress = cost.progress_bar ? cost.progress_bar : 0;
      if (progress >= 100) {
        return "background: #fe4d97;width:100%";
      } else if (progress >= 60) {
        return "background: #ffab2b;width:" + progress + "%";
      } else {
        return "background: #6dd230;width:" + progress + "%";
      }
    },
    navEditMode(ev, mode) {
      this.$store.dispatch("setSelectedObject", {
        cost_uid: ev.uid,
        mode: mode,
      });
      this.$store.dispatch("setSliderView", "JobCostSlider");
    },
    nav(ev) {
      this.$store.dispatch("setSelectedObject", {
        cost_uid: ev.uid,
      });
      this.$store.dispatch("setSliderView", "JobCostSlider");
    },
    postGroup(n) {
      if (n && n.length > 0) {
        const job_uid = this.$route.params.uid;
        var form = new FormData();
        form.append("title", n);
        const title = n;
        form.append("job", job_uid);
        this.$axios
          .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/group", form, {
            headers: { "Content-type": "application/x-www-form-urlencoded" },
          })
          .then((response) => {
            (this.newGroup = null),
              this.$toast.add({
                severity: "success",
                summary: "Group Saved",
                detail: title + " has been added",
                life: 3000,
              });
            this.addGroup = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
          });
      }
    },
    updateGroup(n) {
      if (n && n.length > 0) {
        var form = new FormData();
        form.append("title", n);
        const title = n;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API + "/v1/tasks/group/" + this.editGroup,
            form,
            {
              headers: { "Content-type": "application/x-www-form-urlencoded" },
            }
          )
          .then((response) => {
            this.editGroup = false;
            this.$toast.add({
              severity: "success",
              summary: "Group Saved",
              detail: title + " has been updated",
              life: 3000,
            });
            this.addGroup = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
          });
      }
    },

    onEndGroup(e) {
      var form = new FormData();
      const job_uid = this.$route.params.uid;
      form.append("job", job_uid);
      var sortlist = "";
      this.costs.forEach((value) => {
        sortlist += value.uid + ",";
      });
      form.append("sortlist", sortlist);
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/group/sort", form, {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          //
        })
        .catch((error) => {
          console.error(error);
        });
    },
    expand(uid) {
      const index = this.minimized.indexOf(uid);

      var table = document.getElementsByName("table-" + uid)[0];

      var rows = table.getElementsByTagName("tr");
      var height = 0;
      for (var i = 0; i < rows.length; i++) {
        height += rows[i].getBoundingClientRect().height;
      }

      if (index > -1 && table) {
        table.style.maxHeight = "100%";
      } else {
        table.style.maxHeight = height + "px";
        setTimeout(() => {
          table.style.overflow = "hidden";
          table.style.maxHeight = "42px";
        }, 300);
      }

      if (index > -1) {
        this.minimized.splice(index, 1); // 2nd parameter means remove one item only
      } else {
        this.minimized.push(uid);
      }
    },
    onRowReorder(e, ev) {
      e.costs = ev.value;
      var form = new FormData();

      const job_uid = this.$route.params.uid;
      form.append("cost", ev.value[ev.dragIndex]);
      form.append("group", e.uid);
      form.append("job", job_uid);
      var sortlist = "";

      e.costs = ev.value;
      ev.value.forEach((value) => {
        sortlist += value.uid + ",";
      });
      form.append("sortlist", sortlist);
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/group/move", form, {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          //
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  beforeMount() {
    temp_state.tagsExpanded = false;
  },
  computed: {
    job() {
      return state.job;
    },
    tags_expanded() {
      return temp_state.tagsExpanded;
    },
    user() {
      return this.$store.getters.user;
    },
    account_uid() {
      return this.user?.account_uid;
    },
    costs() {
      return state.jobCosts?.filter((a) => a.costs?.length > 0) || [];
    },
    is_complete() {
      var o = 0;
      if (this.costs) {
        this.costs.forEach((g) => {
          g.costs.forEach((c) => {
            if (c.is_complete == 1) o++;
          });
        });
      }
      return o;
    },
    invoiced() {
      var o = 0;
      if (this.jobCosts) {
        this.jobCosts.forEach((g) => {
          g.costs.forEach((c) => {
            if (c.is_invoiced == 1) o++;
          });
        });
      }
      return o;
    },
  },
  watch: {
    async isActive(n, o) {
      if (n === true) {
        this.loadData();
      }
    },
  },
};
</script>
<style>
.slide-fade-enter-active {
  transition: all 0.4s ease-in;
  transition-delay: 0.2s !important;
}

.slide-fade-enter-from {
  transform: translateX(50px);
  opacity: 0;
}
.task-icon-complete {
  color: #6dd230 !important;
}
.grey-out {
  opacity: 0.5 !important;
  /* background-color: rgb(249, 249, 249) !important; */
}
</style>