<template>
  <th
    class="flex header task-header pr-2 cursor-pointer"
    @click="handleClick"
    :style="
      'background-color: #fdfdfd;' +
      (!showUnplanned && hasPlannedTasks
        ? 'border-bottom: 4px solid #f2f4f6;'
        : '')
    "
  >
    <div class="col-1"></div>
    <div class="col-7 flex vertical-middle p-02">
      <div class="font-light align-self-center flex" style="font-size: smaller">
        <span>Unplanned Tasks</span>
      </div>
    </div>
    <div class="col inline-flex align-self-center justify-content-between">
      <div class="col p-0">
        <span
          :class="
            'p-badge p-component w-3rem ' +
            (showUnplanned ? 'hidden-unplanned' : 'unplanned-hours')
          "
          >{{
            !showUnplanned
              ? totalUnplannedHours
                ? totalUnplannedHours
                : "00:00"
              : "0"
          }}
        </span>
      </div>
      <div class="absolute right-0 mr-3 align-self-center">
        <i
          :class="
            'cursor-pointer align-self-center la la-angle-up icon ' +
            (showUnplanned ? ' icon-collapsed' : ' icon-expanded')
          "
          style="font-size: smaller"
        />
      </div>
    </div>
  </th>
</template>
  
  <script>
export default {
  name: "TaskScheduleUnplannedTasks",
  props: {
    userTasks: {
      type: Array,
      default: () => [],
    },
    workingHours: {
      type: Object,
      default: () => ({}),
    },
    showUnplanned: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
    totalUnplannedHours: {
      type: String,
    },
    hasPlannedTasks: {
      type: Boolean,
    },
  },
  methods: {
    handleClick() {
      this.$emit("toggleExpand");
    },
  },
  computed: {},
};
</script>
  
  <style scoped>
.header {
  max-width: 300px;
}
.header-background-left {
  background-color: #f8fafb;
  margin: 4px -10px 4px 12px;
  border-radius: 6px 0 0 6px;
  pointer-events: none;
}
.header-background-right {
  background-color: #f8fafb;
  margin: 4px 12px 4px 0;
  border-radius: 0 6px 6px 0;
  pointer-events: none;
  padding: 4px;
}

/* Icon animation */
.icon {
  display: inline-block;
  transition: transform 0.3s ease;
}

.icon-expanded {
  transform: rotate(-180deg);
}

.icon-collapsed {
  transform: rotate(0deg);
}

.unplanned-hours {
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #6dd230;
  color: #6dd230;
  box-sizing: border-box;
  padding-right: 1px;
  padding-left: 1px;
}

.hidden-unplanned {
  border-color: transparent;
  background-color: transparent;
  color: transparent;
  box-sizing: border-box;
  padding-right: 1px;
  padding-left: 1px;
  border-style: solid;
  border-width: 1px;
}
</style>
  