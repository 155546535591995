<template>
  <div class="grid">
    <div
      class="flex flex-wrap w-full justify-content-between align-items-center"
    >
      <div
        class="w-full sm:w-min flex sm:justify-content-start justify-content-center align-items-center"
      >
        <h1 class="p-3 mr-1">Suppliers</h1>
      </div>

      <div
        class="flex flex-wrap sm:justify-content-end justify-content-center align-items-center pb-2"
      ></div>
    </div>

    <div class="card col-12 pt-3">
      <div
        class="flex flex-wrap overflow-x-scroll justify-content-center sm:justify-content-end"
      >
        <div class="flex text-center">
          <div class="mr-3">
            <FilterSearchButton v-model="tableFilters['global'].value" />
          </div>
          <div class="mr-3">
            <Button
              label="New Supplier"
              icon="las la-plus"
              @click="$router.push('supplier')"
            />
          </div>
          <!-- <div class="mr-3">
            <Dropdown
              :options="suppliers"
              placeholder="Filter by"
              :filter="true"
              dataKey="uid"
              optionLabel="company_name"
              :showClear="true"
              optionValue="uid"
              v-model="filteredSupplier"
            />
          </div> -->
        </div>
      </div>
      <DataTable
        responsiveLayout="scroll"
        :value="filtered()"
        editMode="row"
        :rowClass="rowClass"
        dataKey="uid"
        :filters.sync="tableFilters"
        filterDisplay="menu"
        :paginator="true"
        :rows="30"
        :rowsPerPageOptions="[30, 50, 100]"
      >
        <template #empty>
          <Button
            label=" New Supplier "
            class="p-button-text p-button-secondary"
            icon="las la-plus"
            @click="$router.push('supplier')"
        /></template>
        <Column
          sortable
          header="Supplier"
          field="company_name"
          :styles="{ width: '20%' }"
        >
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by supplier name"
            />
          </template>
          <template #body="slotProps">
            <Skeleton v-if="isLoading" width="65%"></Skeleton>
            <Skeleton class="mt-1" width="50%" v-if="isLoading"></Skeleton>
            <template v-else>
              <div @click="nav(slotProps)">
                <div class="cursor-pointer">
                  {{ slotProps.data[slotProps.column.field] }}
                </div>
                <div class="font-light text-sm cursor-pointer">
                  {{ slotProps.data.supplier_code }}
                </div>
              </div>
            </template>
          </template>
        </Column>
        <Column header="Contact" :styles="{ width: '20%' }">
          <template #body="slotProps">
            <Skeleton v-if="isLoading" width="50%"></Skeleton>
            <template v-else>
              <div v-if="slotProps.data.contacts[0]">
                {{ slotProps.data.contacts[0]?.name }}
                {{ slotProps.data.contacts[0]?.surname }}
              </div>
              <div
                @click="navContactSlider(slotProps)"
                class="nav-contact"
                v-else
              >
                {{ "Not Added" }}
              </div>
            </template>
          </template>
        </Column>
        <Column sortable header="Active POs" :styles="{ width: '20%' }">
          <template #body="">
            <Skeleton class="mt-1" width="75%" v-if="isLoading"></Skeleton>
            <template v-else>
              <div>0</div>
            </template>
          </template>
        </Column>
        <Column sortable header="On Order" :styles="{ width: '20%' }">
          <template>
            <Skeleton v-if="isLoading"></Skeleton>
            <Skeleton class="mt-1" width="75%" v-if="isLoading"></Skeleton>
            <template v-else> Something </template>
          </template>
        </Column>
        <Column
          sortable
          header="Active"
          field="active"
          :styles="{ width: '20%' }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading" width="30%"></Skeleton>
            <template v-else>
              <AActiveSwitch
                v-model="slotProps.data[slotProps.column.field]"
                @change="handleActive(slotProps.data)"
              />
            </template>
          </template>
        </Column>
        <Column bodyStyle="text-align:right" :styles="{ width: '1rem' }">
          <template #body="slotProps">
            <div
              class="flex justify-content-end align-items-center invisible cursor-pointer"
            >
              <i @click="nav(slotProps)" class="las la-pen i-20 grey pr-1" />
              <i
                @click="
                  deletePrompt(
                    slotProps.data.company_name,
                    '/v1/costtype/delete/supplier/' + slotProps.data.uid
                  )
                "
                class="las la-trash i-20 grey"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { state, fetchSuppliers } from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      filteredSupplier: null,
      isLoading: true,
      tableFilters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        company_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filters: [],
    };
  },
  async mounted() {
    if (!this.suppliers) {
      state.suppliers = [{}, {}, {}, {}, {}, {}];
    }
    await this.loadData();
    this.isLoading = false;
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  methods: {
    navContactSlider(ev) {
      this.$router.push("/supplier/" + ev.data.uid);
      this.$store.dispatch("setSliderView", "ContactSlider");
      this.$store.dispatch("setSelectedObject", {
        obj: ev.data,
      });
    },
    filtered() {
      if (this.filteredSupplier != null) {
        var arr = [];
        this.suppliers.forEach((supplier) => {
          if (supplier.uid === this.filteredSupplier) {
            arr.push(supplier);
          }
        });
        return arr;
      } else {
        return this.suppliers;
      }
    },
    async loadData() {
      await fetchSuppliers();
    },
    rowClass() {
      return "row-accessories row-accessories2";
    },
    handleActive(obj) {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API +
            "/v1/costtype/update/supplier/" +
            obj.uid,

          obj,
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: `${obj.company_name} ${
              obj.active == 1 ? "activated" : "deactivated"
            }`,
            life: 3000,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    nav(ev) {
      this.$router.push("/supplier/" + ev.data.uid);
    },
  },
  computed: {
    suppliers() {
      return state.suppliers;
    },
  },
  metaInfo: {
    title: "Suppliers",
    meta: [
      { vmid: "description", name: "description", content: "Suppliers Page" },
    ],
  },
};
</script>
<style>
.nav-contact {
  color: #778ca2;
}
.nav-contact:hover {
  color: #4d7cfe;
}
</style>