<template>
  <div class="card pt-2">
    <div
      class="flex flex-wrap sm:justify-content-end justify-content-center align-items-center pb-2"
    >
      <div>
        <Button
          class="p-button-text p-button-secondary"
          v-if="isCollapse"
          label="Expand"
          @click="toggleShowDescripton"
          iconPos="right"
          icon="las la-expand"
        />

        <Button
          v-else
          class="p-button-text p-button-secondary"
          iconPos="right"
          label="Collapse"
          @click="toggleShowDescripton"
          icon="las la-compress"
        />
      </div>
      <div class="mr-3">
        <FilterSearchButton v-model="filters['global'].value" />
      </div>
      <div class="mr-3">
        <Button
          label="New Cost"
          @click="$store.dispatch('setSliderView', 'CostSlider')"
          icon="las la-plus"
        />
      </div>
    </div>

    <div>
      <DataTable
        responsiveLayout="scroll"
        :rowClass="rowClass"
        :value="cost_type"
        editMode="row"
        :filters.sync="filters"
        filterDisplay="menu"
        dataKey="uid"
        :editingRows.sync="editingRows"
        @row-edit-cancel="onRowEditCancel"
        @row-edit-save="saveRow"
        :paginator="true"
        :rows="30"
        :rowsPerPageOptions="[30, 50, 100]"
      >
        <template #empty>
          <Button
            v-if="!filters['global'].value"
            label="New Cost"
            class="p-button-text p-button-secondary"
            @click="$store.dispatch('setSliderView', 'CostSlider')"
            icon="las la-plus"
          />
          <div v-else>No Results</div>
        </template>

        <Column
          sortable=""
          field="type_name"
          header="Name"
          :styles="{ width: '20%' }"
        >
          <template #body="slotProps">
            <Skeleton width="55%" v-if="isLoading"></Skeleton>
            <template v-else>
              <span
                class="cursor-pointer"
                @click="
                  $store.dispatch('setSelectedObject', slotProps.data);
                  $store.dispatch('setSliderView', 'CostSlider');
                "
              >
                {{ slotProps.data.type_name }}
              </span>
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by name"
            />
          </template>
          <template #editor="slotProps">
            <InputText placeHolder="Name" v-model="slotProps.data.type_name" />
          </template>
        </Column>
        <Column field="description" header="Description">
          <template #body="slotProps">
            <Skeleton width="75%" v-if="isLoading"></Skeleton>
            <template v-else>
              <div :style="isCollapse ? 'height: 17px; overflow: hidden' : ''">
                <div
                  v-html="markDown(slotProps.data[slotProps.column.field])"
                ></div>
              </div>
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by description"
            />
          </template>
          <template #editor="slotProps">
            <InputText
              placeHolder="Description"
              v-model="slotProps.data[slotProps.column.field]"
            />
          </template>
        </Column>

        <!-- <Column field="description" header="Description">
          <template #body="slotProps">
            <Skeleton width="75%" v-if="isLoading"></Skeleton>
            <template v-else>
              <div v-html="slotProps.data[slotProps.column.field]"></div>
            </template>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by description"
            />
          </template>
          <template #editor="slotProps">
            <InputText
              placeHolder="Description"
              v-model="slotProps.data[slotProps.column.field]"
            />
          </template>
        </Column> -->
        <Column
          field="resell_amount"
          header="Resell Amount"
          bodyClass="resellAmountClass"
        >
          <template #body="slotProps">
            <Skeleton width="35%" v-if="isLoading"></Skeleton>
            <template v-else>
              {{
                formatCurrency(
                  slotProps.data[slotProps.column.field]
                    ? slotProps.data[slotProps.column.field]
                    : 0,
                  slotProps.data.currency?.description
                )
              }}
            </template>
          </template>
          <template #editor="slotProps">
            <InputText
              placeHolder="Price"
              v-model="slotProps.data[slotProps.column.field]"
            />
          </template>
        </Column>

        <Column sortable field="active" header="Active">
          <template #body="slotProps">
            <Skeleton
              width="4rem"
              height="2rem"
              borderRadius="16px"
              v-if="isLoading"
            ></Skeleton>
            <template v-else>
              <AActiveSwitch
                @change="saveRow(slotProps.data)"
                v-model="slotProps.data[slotProps.column.field]"
              />
            </template>
          </template>
        </Column>
        <Column bodyStyle="text-align:right" :styles="{ width: '1rem' }">
          <template #body="slotProps">
            <div
              class="flex justify-content-end align-items-center invisible cursor-pointer"
            >
              <template v-if="editingRow !== slotProps.data.uid">
                <!-- <i
                  @click="editRow(slotProps.data, cost_type)"
                  :class="getEditClass(rowInEdit)"
                ></i> -->

                <i
                  @click="
                    deletePrompt(
                      slotProps.data.type_name,
                      '/v1/costtype/delete/' + slotProps.data.uid
                    )
                  "
                  :class="getDeleteClass(rowInEdit)"
                />
              </template>
              <template v-else>
                <i
                  class="las la-check i-20 mr-3 grey"
                  @click="saveRow(slotProps.data)"
                />
                <i
                  class="las la-times i-20 grey"
                  @click="onRowEditCancel(slotProps.data, cost_type)"
                />
              </template>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <!-- 
    <Button
      label="New Cost"
      class="p-button-text p-button-secondary mt-5"
      @click="$store.dispatch('setSliderView', 'CostSlider')"
      icon="las la-plus"
    /> -->
  </div>
</template>

<script>
import {
  state,
  fetchAccountCostTypes,
  fetchCurrency,
} from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";
import Vue from "vue";
export default {
  data() {
    return {
      isLoading: true,
      isCollapse: true,
      drag: false,
      originalRows: [],
      editingRows: [],
      editingRow: null,
      rowInEdit: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        type_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  computed: {
    cost_type() {
      return state.costTypes;
    },
  },
  async mounted() {
    if (!this.cost_type) {
      state.costTypes = [{}, {}, {}, {}, {}, {}];
    }
    await this.loadData();
    this.isLoading = false;
  },
  methods: {
    markDown(text) {
      return text.replace(/\n/g, "<br>");
    },
    expandRow(value) {
      value.isCollapse = !value.isCollapse;
    },
    toggleShowDescripton() {
      this.isCollapse = !this.isCollapse;
    },
    async loadData() {
      await fetchAccountCostTypes(1);
    },
    getEditClass(t) {
      return t === true ? "hidden" : "las la-pen i-20 mr-3 grey";
    },
    getDeleteClass(t) {
      return t === true ? "hidden" : "las la-trash i-20 grey";
    },
    rowClass() {
      return "row-accessories";
    },

    editRow(r, arr) {
      this.rowInEdit = true;
      this.editingRow = r.uid;
      this.editingRows.push(r);
      var index = arr.indexOf(r);
      this.originalRows[index] = {
        ...arr[index],
      };
    },
    saveRow(r) {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/costtype/" + r.uid,
          JSON.stringify(r),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.rowInEdit = false;
          this.editingRow = null;
          var i2 = this.editingRows.indexOf(r);
          this.editingRows.splice(i2, 1);
          this.$toast.add({
            severity: "success",
            summary: "Cost Type Updated",
            life: 3000,
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },

    onRowEditCancel(r, arr) {
      this.rowInEdit = false;
      var index = arr.indexOf(r);
      Vue.set(arr, index, this.originalRows[index]);
      this.editingRow = null;
      var i2 = this.editingRows.indexOf(r);
      this.editingRows.splice(i2, 1);
    },
  },
  metaInfo: {
    title: "Settings | Cost Types",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>
