<template>
  <div class="card pt-2">
    <div
      class="flex flex-wrap sm:justify-content-between justify-content-center align-items-center pb-2"
    >
      <div class="flex">
        <div class="mr-3 text-primary cursor-pointer">Team</div>
        <div class="font-light cursor-pointer">Invitations</div>
      </div>
      <div class="flex">
        <div class="mr-3">
          <FilterSearchButton v-model="filters['global'].value" />
        </div>
        <div class="mr-2">
          <Button
            label="Invite User"
            @click="display = true"
            icon="las la-user-plus"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-wrap justify-content-between align-items-center pb-2">
      <Dropdown
        optionValue="department_name"
        :showClear="true"
        :filter="true"
        class="filterDD"
        optionLabel="department_name"
        placeholder="All Departments"
        :options="departments"
        @change="handleDepartmentFilterChange"
        v-model="departmentFilterName"
      >
        <template #indicator>
          <i class="la la-filter" />
        </template>
      </Dropdown>
      <div class="flex col p-0">
        <div v-for="user in userData" :key="user.header">
          <div
            v-if="user.data.length > 0"
            :class="
              isButtonPressed === user.header
                ? 'bottom-border-positive'
                : 'bottom-border-invisible'
            "
            class="flex align-items-center font-light cursor-pointer pr-4"
          >
            <div>
              <Button
                :label="user.header"
                class="p-button-text p-button-link p-button-secondary"
                @click="filtered(user)"
              />
            </div>
            <Badge
              v-if="user.data.length > 0"
              :style="user.style"
              class="ml-2"
              :value="user.data.length"
            />
          </div>
        </div>
        <!-- <div class="flex col align-items-center mr-1">
          <span class="font-light mr-3">Show Inactive Users</span>
          <InputSwitch class="green" v-model="showInactiveUsers" />
        </div> -->
      </div>
      <div class="flex font-light" style="font-size: smaller">
        You are on the Pro Plan. 5 - 10 Team Members.
      </div>
    </div>
    <DataTable
      responsiveLayout="scroll"
      :value="combinedUsers"
      :rowClass="rowClass"
      :filters.sync="filters"
      editMode="row"
      dataKey="uid"
    >
      <!-- :filters.sync="filters" -->
      <template #empty>
        <Button
          v-if="!filters['global'].value"
          label="Invite User"
          class="p-button-text p-button-secondary"
          @click="display = true"
          icon="las la-user-plus"
        />
        <div v-else>No Results</div>
      </template>
      <Column
        field="name"
        header="User"
        filterField="name"
        :styles="{ width: '20%' }"
      >
        <template #header>
          <div class="p-column-title">
            {{ combineUserData.name }}
          </div>
        </template>
        <template #body="slotProps">
          <div class="flex align-items-center">
            <div class="mr-3">
              <div v-if="isLoading">
                <Skeleton shape="circle" width="3rem" height="3rem" />
              </div>
              <Avatar
                v-else
                :image="slotProps.data.profile_picture"
                :label="
                  !slotProps.data.profile_picture
                    ? getIntials(slotProps.data)
                    : null
                "
                :style="
                  !slotProps.data.profile_picture
                    ? {
                        'background-color': getColorFromName(slotProps.data),
                        color: '#ffffff',
                      }
                    : ''
                "
                size="large"
                shape="circle"
              />
            </div>
            <Skeleton width="40%" v-if="isLoading"></Skeleton>
            <template v-else>
              <div
                @click="
                  $store.dispatch('setSelectedObject', slotProps.data.uid);
                  $store.dispatch('setSliderView', 'UserSlider');
                "
              >
                {{ slotProps.data["name"] }} {{ slotProps.data["surname"] }}
              </div>
            </template>
          </div>
        </template>
      </Column>

      <!-- <Column header="Primary Role" field="primary_role">
        <template #body="slotProps">
          <Skeleton width="50%" v-if="isLoading"></Skeleton>
          <template v-else>
            <div class="flex flex-wrap align-items-center">
              {{ slotProps.data["primary_role"] }}
              <div class="pl-4">
                <Button
                  v-if="slotProps.data.primary_role == 'Assign'"
                  class="p-button-rounded p-button-lg p-button-text p-component p-button-icon-only"
                  style="color: #6dd230"
                  @click="
                    $store.dispatch('setSelectedObject', slotProps.data.uid);
                    $store.dispatch('setSliderView', 'UserSlider');
                  "
                  icon="las la-arrow-right"
                >
                </Button>
              </div>
            </div>
          </template>
        </template>
      </Column> -->
      <Column header="Department" field="primary_department" sortable>
        <template #body="slotProps">
          <Skeleton width="50%" v-if="isLoading"></Skeleton>
          <template v-else>
            <span
              style="
                background: rgb(232, 236, 239) !important;
                border-radius: 12px;
              "
              v-for="(o, index) in getDepartmentNames(slotProps.data)"
              :key="index"
              class="p-2 mr-2 font-light"
            >
              {{ o.department_name }}
            </span>
          </template>
        </template>
      </Column>
      <Column header="Permission" field="permissions">
        <template #body="slotProps">
          <Skeleton width="40%" v-if="isLoading"></Skeleton>
          <template v-else>
            {{ showPermissionName(slotProps.data) }}
          </template>
        </template>
      </Column>
      <Column header="User Type" field="userType">
        <template #body="slotProps">
          <Skeleton width="40%" v-if="isLoading"></Skeleton>
          <template v-else>
            {{ slotProps.data["userType"] }}
          </template>
        </template>
      </Column>

      <Column
        field="active"
        header="Status"
        bodyStyle="transform: translateY(6px);"
      >
        <template #body="slotProps">
          <Skeleton
            width="4rem"
            height="2rem"
            borderRadius="16px"
            v-if="isLoading"
          ></Skeleton>
          <template v-else>
            <AActiveSwitch
              @change="saveRow(slotProps.data)"
              v-model="slotProps.data[slotProps.column.field]"
            />
          </template>
        </template>
      </Column>
    </DataTable>
    <!-- </div> -->
    <InviteUser :display.sync="display" @dismiss="display = false" />
  </div>
</template>
<script>
import InviteUser from "../components/InviteUser.vue";
import {
  state,
  fetchSettingsUsers,
  fetchDepartments,
} from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";
export default {
  data() {
    return {
      display: false,
      isLoading: true,
      departmentFilterName: null,
      combinedUsers: null,
      errors: [],
      permissions: [
        { permission_id: 1, name: "Admin" },
        { permission_id: 2, name: "User" },
      ],
      editingRows: [],
      editingRow: null,
      search: false,
      departmentFilter: null,
      showInactiveUsers: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        primary_role: { value: null, matchMode: FilterMatchMode.CONTAINS },
        primary_department: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        userType: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      notasks: "",
      user: "",
      isButtonPressed: null,
    };
  },
  watch: {
    isButtonPressed(n) {
      this.combinedUsers = this.combineUserData;
    },
    departmentFilter(n) {
      if (n && n.department_name) {
        this.filters["primary_department"].value = n.department_name;
      } else {
        this.filters["primary_department"].value = null;
      }
    },
  },
  components: { InviteUser },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  methods: {
    showPermissionName(row) {
      return this.permissions.filter(
        (item) => item.permission_id === row.permission_id
      )[0].name;
    },
    getDepartmentNames(department) {
      const departmentNames = state.departments.filter((item) =>
        department.departments.some(
          (deparmentUid) => deparmentUid.uid === item.uid
        )
      );
      return departmentNames;
    },
    rowClass() {
      return "row-accessories";
    },
    async loadData() {
      await fetchSettingsUsers();
      await fetchDepartments();
      this.combinedUsers = this.combineUserData;
    },
    filtered(user) {
      if (user.header === this.isButtonPressed) {
        return (this.isButtonPressed = null);
      } else {
        switch (user.header) {
          case "Active Users":
            this.isButtonPressed = "Active Users";
            this.showInactiveUsers = false;
            break;
          case "Inactive Users":
            this.isButtonPressed = "Inactive Users";
            this.showInactiveUsers = false;
            break;
          default:
            return this.isButtonPressed;
        }
      }
    },
    handleDepartmentFilterChange(value) {
      if (value.value == null) {
        this.combinedUsers = this.combineUserData;
        return;
      }
      const combinedDepartmentDeta = this.combineUserData.map((item) => {
        return { ...item, departments: this.getDepartmentNames(item) };
      });
      const filteredUsers = combinedDepartmentDeta.filter((user) =>
        user.departments.some(
          (department) => department.department_name === value.value
        )
      );

      this.combinedUsers = filteredUsers;
    },
    saveRow(r) {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/users/status/" + r.uid,
          JSON.stringify(r),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.editingRow = null;
          var i2 = this.editingRows.indexOf(r);
          this.editingRows.splice(i2, 1);
          this.$toast.add({
            severity: "success",
            summary: "User Status Updated",
            life: 3000,
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
  },

  computed: {
    departments() {
      return state.departments;
    },
    sliderView() {
      return this.$store.getters.sliderView;
    },
    users() {
      return state.settingsUsers;
    },
    combineUserData() {
      if (!this.users) return;
      var combineUsers = null;
      switch (this.isButtonPressed) {
        case "Active Users":
          combineUsers = this.users.filter((a) => a.active === 1);

          break;
        case "Inactive Users":
          combineUsers = this.users.filter((a) => a.active === 0);
          break;
        case null:
          combineUsers = this.users;
          break;

        default:
          if (this.showInactiveUsers !== true) {
            return state.settingsUsers.filter((a) => a.active === 1);
          }
          return state.settingsUsers;
      }
      return combineUsers;
    },
    userData() {
      if (!this.users) return;
      var users = [];
      var unassignedUsers = this.users.filter((a) => a.active === 0);
      var assignedUsers = this.users.filter((a) => a.active === 1);

      assignedUsers.forEach((a) => {
        if (a.contract === 1) {
          a.userType = "Freelancer";
        } else {
          a.userType = "Inhouse";
        }
      });

      unassignedUsers.forEach((a) => {
        if (a.contract === 1) {
          a.userType = "Freelancer";
        } else {
          a.userType = "Inhouse";
        }
      });
      users.push({
        header: "Active Users",
        data: assignedUsers,
        style: "background-color: #6dd230",
      });
      users.push({
        header: "Inactive Users",
        data: unassignedUsers,
        style: "background-color: rgb(232, 236, 239);",
      });

      return users;
    },
  },
  async mounted() {
    if (!this.users) {
      state.settingsUsers = [{}, {}, {}, {}, {}, {}];
    }
    this.filtered({
      header: "Active Users",
    });
    await this.loadData();

    this.isLoading = false;
  },

  name: "UserList",

  metaInfo: {
    title: "Settings | Users",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>

<style scoped>
.p-button-link {
  color: #6dd230;
}
</style>
