<template>
  <div>
    <div class="card pt-3 sticky-header" align="left">
      <div class="flex justify-content-between pb-2 mb-2">
        <div class="col">
          <div>
            <h1 v-if="$route.params?.uid">Invoices</h1>
            <h1 v-else>Finance</h1>
          </div>
        </div>
        <div class="col flex justify-content-end">
          <div class="pr-1" v-if="$route.params?.uid">
            <Button
              label="New Payment"
              class="p-button-text p-button-secondary button-outline"
              icon="las la-plus"
              @click="$store.dispatch('setSliderView', 'PaymentSlider')"
            />
          </div>
          <div class="pl-1 pr-1" v-if="$route.params?.uid">
            <Button
              label="New Invoice"
              icon="las la-plus"
              @click="newInvoice()"
            />
          </div>
        </div>
      </div>

      <Dropdown
        class="w-full mb-2 md:hidden"
        :options="categories"
        optionLabel="label"
        optionValue="type"
        placeholder="Filter by category"
      />
      <DataTable
        ref="dt"
        :scrollable="true"
        :scrollHeight="dataTableScrollHeight"
        responsiveLayout="scroll"
        :rowClass="rowClass"
        :value="filterInvoices()"
        editMode="row"
        :filters.sync="tablefilters"
        class="min-300"
        filterDisplay="menu"
        dataKey="idKey"
        :rows="30"
        :rowsPerPageOptions="[30, 50, 100]"
      >
        <template #empty> <div>No Invoices Yet</div></template>
        <ColumnGroup type="header">
          <Row>
            <Column
              :colspan="6"
              :headerStyle="{
                padding: '0px',
                backgroundColor: '#FFFFFF',
                borderBottom: 'none',
              }"
            >
              <template #header
                ><div
                  v-for="category in getFilterCategories(categories)"
                  :key="category.type"
                  :class="getCategoryClass(category)"
                  @click="filtering(category.type)"
                >
                  {{ category.label }}
                  <Badge
                    v-if="isLoading === false"
                    :style="getBadgeStyle(category)"
                    class="ml-2 hidden md:block"
                    :value="getBadgeValue(category)"
                  />
                  <Badge
                    v-else
                    style="background-color: #e8ecef"
                    class="ml-2"
                  />
                </div>
                <div class="p-float-label w-full md:w-14rem col-1">
                  <Dropdown
                    showClear
                    inputId="dd-city"
                    placeholder="Select Date "
                    :options="availableDates"
                    v-model="dateFilter"
                  /></div
              ></template>
            </Column>

            <Column
              :headerStyle="{
                backgroundColor: '#FFFFFF',
                borderBottom: 'none',
              }"
              :colspan="1"
            >
              <template #header
                ><div
                  v-if="!isLoading"
                  class="font-light"
                  style="width: 100%; text-align-last: right;
}"
                >
                  Totals:
                </div></template
              >
            </Column>
            <Column
              :headerStyle="{
                backgroundColor: '#FFFFFF',
                borderBottom: 'none',
              }"
              :colspan="1"
            >
              <template #header>
                <Skeleton
                  class="mt-1"
                  width="100px"
                  v-if="isLoading"
                ></Skeleton>
                <span v-else class="font-bold">
                  {{
                    formatCurrency(totalEx, job?.client?.currency?.description)
                  }}</span
                ></template
              >
            </Column>
            <Column
              :headerStyle="{
                backgroundColor: '#FFFFFF',
                borderBottom: 'none',
              }"
              :colspan="1"
            >
              <template #header>
                <Skeleton
                  class="mt-1"
                  width="100px"
                  v-if="isLoading"
                ></Skeleton>
                <span v-else class="font-bold">
                  {{
                    formatCurrency(totalInc, job?.client?.currency?.description)
                  }}</span
                ></template
              >
            </Column>
            <Column
              :headerStyle="{
                backgroundColor: '#FFFFFF',
                borderBottom: 'none',
              }"
              :colspan="integrationId ? 2 : 1"
            >
              <template #header>
                <FilterSearchButton v-model="tablefilters['global'].value"
              /></template>
            </Column>
          </Row>
          <Row>
            <Column
              sortable
              field="document_number"
              header="No"
              :filterMenuStyle="{ width: '13%' }"
              :styles="{ width: '10%', borderTopLeftRadius: '6px' }"
            ></Column>
            <Column
              sortable
              field="name"
              header="Invoice"
              :filterMenuStyle="{ width: '13%' }"
              :styles="{ width: '13%' }"
            >
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by invoice"
                /> </template
            ></Column>
            <Column
              sortable
              field="client_name"
              header="Client"
              :filterMenuStyle="{ width: '13%' }"
              :styles="{ width: '13%' }"
            >
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by client"
                /> </template
            ></Column>
            <Column
              sortable
              field="job_category"
              header="Category"
              :filterMenuStyle="{ width: '13%' }"
              :styles="{ width: '13%' }"
            >
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by category"
                /> </template
            ></Column>
            <Column
              sortable
              field="account_executive"
              header="AE"
              :filterMenuStyle="{ width: '13%' }"
              :styles="{ width: '13%' }"
            >
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by account executive"
                /> </template
            ></Column>
            <Column
              sortable
              field="date_issued"
              header="Date"
              filterField="date"
              dataType="date"
              :filterMenuStyle="{ width: '13%' }"
              :styles="{ width: '13%' }"
            ></Column>
            <Column
              sortable
              field="status"
              header="Status"
              filterField="status"
              :filterMenuStyle="{ width: '13%' }"
              :styles="{ width: '13%' }"
            ></Column>
            <Column
              sortable
              field="amount"
              header="Excl."
              filterField="amount"
              :filterMenuStyle="{ width: '13%' }"
              :styles="{ width: '13%' }"
            ></Column>
            <Column
              sortable
              field="amountVat"
              header="Incl."
              filterField="amount"
              :filterMenuStyle="{ width: '13%' }"
              :styles="{ width: '13%' }"
            ></Column>
            <Column
              header="Synced"
              v-if="integrationId"
              :filterMenuStyle="{ width: '13%' }"
              :styles="{ width: '13%' }"
            ></Column>
            <Column
              bodyStyle="text-align:right"
              :headerStyle="{ borderTopRightRadius: '6px' }"
              :styles="{ width: '2%' }"
            ></Column>
          </Row>
        </ColumnGroup>
        <Column
          sortable
          field="document_number"
          header="No"
          :filterMenuStyle="{ width: '13%' }"
          :styles="{ width: '10%' }"
        >
          <template #body="slotProps">
            <Skeleton class="mt-1" width="75%" v-if="isLoading"></Skeleton>
            <div
              class="cursor-pointer"
              v-else
              @click="openInvoice(slotProps.data.uid, slotProps.data.job_uid)"
            >
              {{ slotProps.data[slotProps.column.field] ?? "Pending" }}
            </div>
          </template>
          <template #editor="slotProps">
            <InputText placeholder="Name" v-model="slotProps.data.type_name" />
          </template>
        </Column>
        <Column
          sortable
          field="name"
          header="Invoice"
          :filterMenuStyle="{ width: '13%' }"
          :styles="{ width: '13%' }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div
              @click="openInvoice(slotProps.data.uid, slotProps.data.job_uid)"
              v-else
            >
              {{ slotProps.data[slotProps.column.field] }}
            </div>
          </template>
        </Column>
        <Column
          sortable
          field="client_name"
          header="Client"
          :filterMenuStyle="{ width: '13%' }"
          :styles="{ width: '13%' }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div
              @click="openInvoice(slotProps.data.uid, slotProps.data.job_uid)"
              v-else
            >
              {{ slotProps.data[slotProps.column.field] }}
            </div>
          </template>
        </Column>
        <Column
          sortable
          field="job_category"
          header="Category"
          :filterMenuStyle="{ width: '13%' }"
          :styles="{ width: '13%' }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div v-else>{{ slotProps.data[slotProps.column.field] }}</div>
          </template>
        </Column>

        <Column
          sortable
          field="account_executive"
          header="AE"
          :filterMenuStyle="{ width: '13%' }"
          :styles="{ width: '13%' }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div
              @click="openInvoice(slotProps.data.uid, slotProps.data.job_uid)"
              v-else
            >
              {{ slotProps.data[slotProps.column.field] }}
            </div>
          </template>
        </Column>
        <Column
          sortable
          field="date_issued"
          header="Date"
          filterField="date"
          dataType="date"
          :filterMenuStyle="{ width: '13%' }"
          :styles="{ width: '13%' }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div v-else-if="slotProps.data">
              {{ slotProps.data[slotProps.column.field] | moment("DD MMM YY") }}
            </div>
          </template>
        </Column>

        <Column
          sortable
          field="status"
          header="Status"
          filterField="status"
          :filterMenuStyle="{ width: '13%' }"
          :styles="{ width: '13%' }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div v-else-if="slotProps.data">
              <div class="text-12">
                <span
                  class="p-2"
                  v-if="slotProps.data[slotProps.column.field] === 'issued'"
                  :style="'background: #FFF8EE !important;color: #FFB441 !important;borderRadius: 6px'"
                >
                  {{
                    toTitleCase(
                      slotProps.data[slotProps.column.field] ?? "Draft"
                    )
                  }}</span
                >

                <span
                  class="p-2"
                  v-else
                  :style="'background: #E8ECEF !important;borderRadius: 6px;'"
                >
                  {{
                    toTitleCase(
                      slotProps.data[slotProps.column.field] ?? "Draft"
                    )
                  }}
                </span>
              </div>
            </div>
          </template>
        </Column>

        <Column
          sortable
          field="amount"
          header="Excl."
          filterField="amount"
          :filterMenuStyle="{ width: '13%' }"
          :styles="{ width: '13%' }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div v-else>
              {{
                formatCurrency(
                  slotProps.data.amount,
                  job?.client?.currency?.description
                )
              }}
            </div>
          </template>
        </Column>
        <Column
          sortable
          field="amountVat"
          header="Incl."
          filterField="amount"
          :filterMenuStyle="{ width: '13%' }"
          :styles="{ width: '13%' }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div v-else>
              {{
                formatCurrency(
                  slotProps.data.amountVat,
                  job?.client?.currency?.description
                )
              }}
            </div>
          </template>
        </Column>
        <Column
          header="Synced"
          v-if="integrationId"
          :filterMenuStyle="{ width: '13%' }"
          :styles="{ width: '13%' }"
        >
          <template #body="slotProps">
            <Skeleton v-if="isLoading"></Skeleton>
            <div v-else-if="slotProps.data">
              <Button
                style="width: 115px; height: 30px; opacity: 1"
                class="text-center"
                :icon="
                  slotProps.data.integration_id ? 'las la-check-circle' : ''
                "
                v-tooltip.bottom="{
                  value: 'Cannot Sync a Draft Invoice',
                  disabled:
                    slotProps.data.integration_id || slotProps.data.status,
                }"
                :label="
                  isSyncingArray.includes(slotProps.data.uid)
                    ? 'Syncing'
                    : slotProps.data.integration_id
                    ? 'Synced'
                    : 'Sync'
                "
                :loading="isSyncingArray.includes(slotProps.data.uid)"
                @click="sync(slotProps.data)"
                :disabled="
                  isSyncingArray.includes(slotProps.data.uid) ||
                  slotProps.data.integration_id ||
                  !slotProps.data.status
                "
                :class="
                  slotProps.data.integration_id
                    ? 'p-button-success'
                    : !slotProps.data.status
                    ? 'p-button-gray cursor-disable'
                    : ''
                "
              ></Button></div
          ></template>
        </Column>
        <Column bodyStyle="text-align:right" :styles="{ width: '2%' }">
          <template #body="slotProps">
            <div
              class="flex justify-content-end align-items-center invisible cursor-pointer"
            >
              <i
                @click.stop="
                  deletePrompt(
                    slotProps.data.document_number ?? ' Draft Invoice',
                    '/v1/finance/invoice/delete/' + slotProps.data.uid,
                    'get'
                  )
                "
                class="las la-trash i-20 grey"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { state, fetchInvoices } from "../../../services/data_service.js";
import Vue from "vue";
export default {
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  name: "Home",
  data() {
    return {
      syncingInvoices: false,
      isSyncingArray: [],
      isLoading: false,
      dateFilter: "Last Month",
      availableDates: [],
      categories: [
        { type: "issued", label: "Issued", color: "#ffab2b" },
        { type: "unsynced", label: "Unsynced", color: "#4D7CFE" },
        { type: "draft", label: "Draft", color: "#e8ecef" },
      ],
      tablefilters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        client_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        job_category: { value: null, matchMode: FilterMatchMode.CONTAINS },
        account_executive: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      filters: [],
      dataTableScrollHeight: "calc(" + window.innerHeight + "px - 222px)",
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
    window.removeEventListener("resize", this.updateScreenHeight);
  },

  async mounted() {
    window.addEventListener("resize", this.updateScreenHeight);
    await this.loadData();
    this.getAvailableDates();
  },

  methods: {
    updateScreenHeight() {
      this.dataTableScrollHeight = "calc(" + window.innerHeight + "px - 222px)";
    },
    async sync(invoice) {
      this.$confirm.require({
        message:
          "Are you sure you want to sync invoice " +
          (invoice.document_number ?? "Pending") +
          "?",
        header: "Confirm Sync to Xero",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.isSyncingArray.push(invoice.uid);
          this.$axios
            .get(`/v1/finance/invoice/sync/${invoice.uid}`)
            .then((res) => {
              if (res.data.data) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Invoice synced successfully",
                  life: 3000,
                });
              }

              this.isSyncingArray = this.isSyncingArray.filter(
                (a) => a !== invoice.uid
              );

              invoice.integration_id = true;
            })
            .catch((e) => {
              this.isSyncingArray = this.isSyncingArray.filter(
                (a) => a !== invoice.uid
              );
            });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },

    getAvailableDates() {
      var today = new Date();

      var invoiceCopy = JSON.parse(JSON.stringify(this.invoices));

      var minInvoiceMonth = new Date(
        Math.min.apply(
          null,
          invoiceCopy.map((invoice) => new Date(invoice.date_issued))
        )
      );

      var monthsBetween = Math.abs(
        today.getMonth() -
          minInvoiceMonth.getMonth() +
          12 * (today.getFullYear() - minInvoiceMonth.getFullYear())
      );

      var array = ["Current Month", "Last Month"];

      for (var i = 0; i < monthsBetween; i++) {
        var month = new Date(today.setMonth(today.getMonth() - 2))
          .toLocaleString("default", { month: "long" })
          .concat(" ", today.getFullYear());
        array.push(month);
      }

      this.availableDates = array;
    },
    rowClass() {
      return "row-accessories row-accessories2";
    },
    async loadData() {
      if (!state.invoices) {
        this.isLoading = true;
        state.invoices = [];
        for (let index = 0; index < 3; index++) {
          state.invoices.push({});
        }
      }
      await fetchInvoices(this.$route.params.uid);
      this.isLoading = false;
    },
    openInvoice(uid, job_uid) {
      if (job_uid) {
        this.$router.push(`/job/${job_uid}/financial/${uid}`);
      } else {
        this.$router.push({
          name: "job--financial",
          params: { invoice_uid: uid },
        });
      }
    },
    newInvoice() {
      this.$store.dispatch("setSelectedObject", null);
      this.$store.dispatch("setSliderView", "InvoiceSlider");
    },
    getCategoryClass(category) {
      return `flex align-items-center p-3 mr-3 font-light cursor-pointer ${this.getBottomBorderStyle(
        category
      )}`;
    },
    getBottomBorderStyle(category) {
      return this.filters.indexOf(category.type) >= 0
        ? this.getBottomBorderPositiveClass(category)
        : "bottom-border-invisible";
    },
    getBottomBorderPositiveClass(category) {
      return category.type === "unsynced"
        ? "bottom-border-primary"
        : category.type === "issued"
        ? "bottom-border-warning"
        : "bottom-border-gray";
    },
    getBadgeStyle(category) {
      return `background-color: ${category.color}`;
    },
    getBadgeValue(category) {
      const value = this[category.type]; // Assuming your data properties match the category types
      return value == null ? 0 : value;
    },
    filterInvoices() {
      var arr = this.invoices;

      if (
        this.filters.indexOf("issued") >= 0 ||
        this.selectedCategory === "issued"
      ) {
        arr = arr?.filter((invoice) => invoice.status === "issued");
      }

      if (
        this.filters.indexOf("unsynced") >= 0 ||
        this.selectedCategory === "unsynced"
      ) {
        arr = arr?.filter(
          (invoice) =>
            invoice.integration_id === null && invoice.status === "issued"
        );
      }

      if (
        this.filters.indexOf("draft") >= 0 ||
        this.selectedCategory === "draft"
      ) {
        arr = arr?.filter((invoice) => !invoice.status);
      }

      return arr;
    },
    filtering(f) {
      var i = this.filters.indexOf(f);
      if (i >= 0) {
        this.filters.splice(i, 1);
      } else {
        this.filters.push(f);
      }
    },
    getFilterCategories(categories) {
      return this.integrationId
        ? categories
        : categories.filter((a) => a.type !== "unsynced");
    },
  },

  computed: {
    totalEx() {
      var s = 0;
      this.invoices?.forEach((invoice) => {
        s += parseFloat(invoice.amount);
      });

      return s;
    },
    totalInc() {
      var s = 0;
      this.invoices?.forEach((invoice) => {
        s += parseFloat(invoice.amountVat);
      });

      return s;
    },
    invoices() {
      if (this.dateFilter && !this.isLoading) {
        if (this.dateFilter === "Current Month") {
          return state.invoices?.filter((invoice) => {
            return (
              new Date(invoice.date_issued).getMonth() === new Date().getMonth()
            );
          });
        } else if (this.dateFilter === "Last Month") {
          return state.invoices?.filter((invoice) => {
            return (
              new Date(invoice.date_issued).getMonth() ===
              new Date().getMonth() - 1
            );
          });
        } else {
          return state.invoices?.filter((invoice) => {
            return (
              new Date(invoice.date_issued)
                .toLocaleString("default", { month: "long" })
                .concat(" ", new Date(invoice.date_issued).getFullYear()) ===
              this.dateFilter
            );
          });
        }
      }

      state.invoices?.forEach((invoice) => {
        invoice.idKey = Math.random().toString(36).substring(7);
        invoice.amountVat =
          invoice.amount * ((100 + parseFloat(invoice.tax_rate ?? 0)) / 100);
      });

      return state.invoices;
    },
    job() {
      return state.job;
    },
    issued() {
      return this.invoices?.filter((a) => a.status === "issued").length;
    },
    unsynced() {
      return this.invoices?.filter(
        (a) => a.integration_id === null && a.status === "issued"
      ).length;
    },
    draft() {
      return this.invoices?.filter((a) => !a.status).length;
    },
    integrationId() {
      return this.$store.getters.integrationId;
    },
  },
  metaInfo: {
    title: "Invoices",
    meta: [{ vmid: "description", name: "description" }],
  },
  watch: {
    async isActive(n, o) {
      if (n === true) {
        await this.loadData();
      }
    },
    "state.invoices"(n, o) {
      if (n?.length - o?.length === 1) {
        this.openInvoice(n[n.length - 1].uid, n[n.length - 1].job_uid);
      }
    },
  },
};
</script>

<style lang="scss">
.min-300 > .p-datatable-wrapper > .p-datatable-table {
  min-height: 300px;
}
</style>