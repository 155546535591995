var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card pt-3"},[_c('div',{staticClass:"flex flex-wrap overflow-x-scroll justify-content-center sm:justify-content-between pb-1"},[_c('div',{staticClass:"flex text-center"},[_c('JobCostingFilter',{attrs:{"draft":_vm.draft,"approved":_vm.approved,"invoiced":_vm.invoiced,"completed":_vm.completed,"filters":_vm.jobFilters},on:{"filter":_vm.filter}})],1),_c('div',{staticClass:"flex text-center"},[_c('div',[(_vm.isCollapse)?_c('Button',{staticClass:"p-button-text p-button-secondary",attrs:{"label":"Expand","iconPos":"right","icon":"las la-expand"},on:{"click":_vm.toggleShowDescripton}}):_c('Button',{staticClass:"p-button-text p-button-secondary",attrs:{"iconPos":"right","label":"Collapse","icon":"las la-compress"},on:{"click":_vm.toggleShowDescripton}})],1),_c('div',{staticClass:"mr-3"},[_c('FilterSearchButton',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(!_vm.addGroup && _vm.items && _vm.items.length > 0)?_c('div',[_c('Button',{directives:[{name:"can",rawName:"v-can",value:('admin'),expression:"'admin'"}],attrs:{"label":"New Group","icon":"las la-plus"},on:{"click":function($event){return _vm.insertGroup()}}})],1):(_vm.addGroup)?_c('div',[_c('InputText',{ref:"groupInput",attrs:{"placeholder":"Group Name"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.postGroup(_vm.newGroup)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined))return null;_vm.newGroup = null;
            _vm.addGroup = false;}]},model:{value:(_vm.newGroup),callback:function ($$v) {_vm.newGroup=$$v},expression:"newGroup"}}),_c('Button',{staticClass:"p-button-rounded grey-button ml-3",attrs:{"icon":"pi pi-check ","disabled":!_vm.newGroup},on:{"click":function($event){return _vm.postGroup(_vm.newGroup)}}}),_c('Button',{staticClass:"p-button-rounded grey-button ml-3",attrs:{"icon":"pi pi-times "},on:{"click":function($event){_vm.newGroup = null;
            _vm.addGroup = false;}}})],1):_vm._e()])]),(_vm.items && _vm.items.length < 1)?_c('div',{staticClass:"flex justify-content-center sm:h-20rem align-items-center sm:p-6 text-16"},[_c('div',{staticStyle:{"display":"inline-block","padding":"30px 80px","background":"#f8fafb","border-radius":"100px"}},[(!_vm.addGroup && _vm.items.length === 0 && !_vm.isLoading)?[_c('a',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.insertGroup()}}},[_vm._v("Create a group")]),_vm._v(" to start adding items.")]:_c('div',{staticClass:"flex"},[_c('InputText',{ref:"groupInput",attrs:{"placeholder":"Group Name"},on:{"blur":function($event){!_vm.newGroup ? (_vm.addGroup = false) : ''},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.postGroup(_vm.newGroup)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined))return null;_vm.newGroup = null;
            _vm.addGroup = false;}]},model:{value:(_vm.newGroup),callback:function ($$v) {_vm.newGroup=$$v},expression:"newGroup"}}),_c('Button',{staticClass:"p-button-rounded grey-button ml-3",attrs:{"icon":"pi pi-check "},on:{"click":function($event){return _vm.postGroup(_vm.newGroup)}}})],1)],2)]):_vm._e(),_c('draggable',{attrs:{"handle":".p-datatable-thead","animation":140,"list":_vm.items,"disabled":!!_vm.editGroup},on:{"end":function($event){return _vm.onEndGroup($event)}}},_vm._l((_vm.items),function(u){return _c('div',{key:u.uid,style:(_vm.minimized.indexOf(u.uid) > -1 || u.tasks?.length + u.costs?.length < 1
          ? 'margin-bottom:24px;'
          : 'margin-bottom:24px;')},[(
          (_vm.hideCompletedItems &&
            u.items.filter((item) => item.is_complete == 0).length > 0) ||
          !_vm.hideCompletedItems
        )?_c('div',[(
            ((_vm.jobFilters?.length > 0 || !!_vm.search) &&
              _vm.notFilteredItems(u)?.length > 0) ||
            (_vm.jobFilters?.length == 0 && !_vm.search)
          )?_c('div',[_c('div',{staticClass:"header-pointer",attrs:{"id":u.uid},on:{"click":_vm.handleHeaderClick}},[(
                (_vm.hideCompletedItems &&
                  u.items.filter((item) => item.is_complete == 0).length >
                    0) ||
                !_vm.hideCompletedItems
              )?_c('DataTable',{staticClass:"dt-class transition-dt no-empty-div",attrs:{"value":_vm.notFilteredItems(u),"responsiveLayout":"scroll","rowClass":_vm.rowClass,"name":'table-' + u.uid,"editMode":"row","dataKey":"uid","tableClass":'drag-drop-table groupTableId-' + u.id},on:{"row-reorder":function($event){return _vm.onRowReorder(u, ...arguments)}},scopedSlots:_vm._u([(_vm.minimized.indexOf(u.uid) < 0)?{key:"footer",fn:function(){return [_c('Button',{staticClass:"p-button-text p-button-secondary ml-3",staticStyle:{"color":"#b9c5d0"},attrs:{"label":"Add Task","icon":"las la-plus"},on:{"click":function($event){_vm.unsetTask();
                    _vm.setitemGroup(u.uid);
                    _vm.$store.dispatch('setSliderView', 'TaskSlider');}}}),_c('Button',{staticClass:"p-button-text p-button-secondary ml-3",staticStyle:{"color":"#b9c5d0"},attrs:{"label":"Add Cost","icon":"las la-plus"},on:{"click":function($event){_vm.setDefaultJobApprove();
                    _vm.$store.dispatch('setSelectedObject', {
                      job: _vm.job,
                      group: u,
                    });
                    _vm.$store.dispatch('setSliderView', 'JobCostSlider');}}})]},proxy:true}:null],null,true)},[_c('Column',{attrs:{"footerStyle":{
                  backgroundColor: 'white',
                },"rowReorder":true,"headerStyle":{ width: '1rem' },"reorderableColumn":false},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.minimized.indexOf(u.uid) > -1)?_c('i',{staticClass:"las la-plus cursor-pointer"}):_c('i',{staticClass:"las la-minus cursor-pointer"})]},proxy:true}],null,true)}),_c('Column',{attrs:{"footerStyle":{
                  backgroundColor: 'white',
                },"field":"title"},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.isLoading)?_c('Skeleton'):(_vm.editGroup !== u.uid)?_c('div',{staticClass:"font-light",staticStyle:{"text-wrap":"nowrap"}},[_vm._v(" "+_vm._s(_vm.groupTitle(u))+" ")]):[_c('InputText',{ref:"groupInput",refInFor:true,attrs:{"placeholder":"Group Name"},on:{"blur":_vm.handleBlur,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updateGroup(_vm.editGroupTitle, u)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined))return null;_vm.editGroupTitle = null;
                        _vm.editGroup = false;}]},model:{value:(_vm.editGroupTitle),callback:function ($$v) {_vm.editGroupTitle=$$v},expression:"editGroupTitle"}}),_c('Button',{staticClass:"p-button-rounded grey-button-flat p-button-text ml-1",attrs:{"icon":"pi pi-check ","disabled":!_vm.editGroupTitle},on:{"click":function($event){$event.stopPropagation();return _vm.updateGroup(_vm.editGroupTitle, u)}}}),_c('Button',{staticClass:"p-button-rounded grey-button-flat p-button-text ml-1",attrs:{"icon":"pi pi-times "},on:{"click":function($event){$event.stopPropagation();_vm.editGroupTitle = null;
                        _vm.editGroup = false;}}})]]},proxy:true},{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('div',[_c('div',{class:'p-1 ' +
                        (slotProps.data.is_complete == 1
                          ? 'font-light'
                          : 'text-link'),attrs:{"id":'rowTask-' + slotProps.data.uid}},[_c('span',{staticClass:"cursor-pointer"},[(slotProps.data.is_complete == 1)?_c('i',{staticClass:"las la-check-circle grey mr-1 task-icon-complete mt-1",staticStyle:{"font-size":"16px"}}):_vm._e(),(slotProps.data.task_type_name)?_c('span',{on:{"click":function($event){return _vm.nav(slotProps.data)}}},[_vm._v(" "+_vm._s(slotProps.data.task_type_name)+" "),(slotProps.data.title != '')?_c('span',[_vm._v(" - "+_vm._s(slotProps.data.title)+" ")]):_vm._e()]):_c('span',{on:{"click":function($event){_vm.$store.dispatch('setSelectedObject', {
                              job: _vm.job,
                              group: u,
                              cost_uid: slotProps.data.uid,
                            });
                            _vm.$store.dispatch('setSliderView', 'JobCostSlider');}}},[_vm._v(" "+_vm._s(slotProps.data.type_name)+" "),(slotProps.data.title)?_c('span',[_vm._v(" - "+_vm._s(slotProps.data.title)+" ")]):_vm._e()])])]),(
                        _vm.checkShouldShowDescription() &&
                        slotProps.data.description
                      )?_c('div',{staticClass:"p-1 flex flex-wrap font-light"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.markDown(slotProps.data.description))}})]):_vm._e()])]}}],null,true)}),_c('Column',{attrs:{"footerStyle":{
                  backgroundColor: 'white',
                },"header":_vm.minimized.indexOf(u.uid) < 0 ? 'Qty' : '',"field":"","styles":{
                  width: '6%',
                  whiteSpace: 'nowrap',
                  verticalAlign: 'top !important',
                }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('div',[_c('div',{staticClass:"col pr-0 ce-co"},[_vm._v(" "+_vm._s(slotProps.data.estimated_hours ? slotProps.data.estimated_hours : slotProps.data.quantity)+" ")])])]}}],null,true)}),_c('Column',{attrs:{"footerStyle":{
                  backgroundColor: 'white',
                },"header":_vm.minimized.indexOf(u.uid) < 0 ? 'Price/Unit' : '',"field":"users","styles":{ width: '6%', verticalAlign: 'top !important' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('div',[_c('div',{staticClass:"col pr-0 ce-co"},[_vm._v(" "+_vm._s(!!slotProps.data.unit_price ? _vm.formatCurrency( slotProps.data.unit_price, _vm.job?.client?.currency?.description ) : _vm.formatCurrency( slotProps.data.rate, _vm.job?.client?.currency?.description ) + "/hr")+" ")])])]}}],null,true)}),_c('Column',{attrs:{"footerStyle":{
                  backgroundColor: 'white',
                },"headerClass":"header-right","header":_vm.minimized.indexOf(u.uid) < 0 ? 'Margin' : '',"styles":{ width: '6%', verticalAlign: 'top !important' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):(slotProps.data && slotProps.data.margin)?_c('div',[_c('div',{staticClass:"col text-end pr-0 ce-co"},[_vm._v(" "+_vm._s(_vm.formatCurrency( slotProps.data.margin, _vm.job?.client?.currency?.description ))+" ")])]):_c('div',{staticClass:"col text-end pr-3"},[_vm._v("-")])]}}],null,true)}),_c('Column',{attrs:{"footerStyle":{
                  backgroundColor: 'white',
                },"headerClass":"header-right","header":_vm.minimized.indexOf(u.uid) < 0 ? 'Total' : '',"field":"active","styles":{ width: '6%', verticalAlign: 'top !important' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('div',[_c('div',{staticClass:"col text-end pr-0 ce-co"},[_vm._v(" "+_vm._s(slotProps.data.total_price !== null ? _vm.formatCurrency( slotProps.data.total_price, _vm.job?.client?.currency?.description ) : _vm.formatCurrency( slotProps.data.price, _vm.job?.client?.currency?.description ))+" ")])])]}}],null,true)}),_c('Column',{attrs:{"footerStyle":{
                  backgroundColor: 'white',
                },"headerStyle":{ textWrap: 'nowrap' },"header":_vm.minimized.indexOf(u.uid) < 0
                    ? 'Status'
                    : u.costs?.length +
                      u.tasks?.length +
                      ' item' +
                      (u.costs?.length + u.tasks?.length === 1 ? '' : 's'),"field":"active","styles":{ width: '10%', verticalAlign: 'top !important' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('div',[_c('div',{staticClass:"col ce-co pl-0"},[_c('div',{staticClass:"text-12",staticStyle:{"width":"30px"}},[(slotProps.data.status == 'invoiced')?_c('span',{staticClass:"p-2",style:('background: #FFF8EE !important;color: #FFB441 !important;borderRadius: 6px')},[_vm._v(_vm._s(slotProps.data.issued === 1 ? "Issued" : "Invoiced")+" ")]):_vm._e(),(slotProps.data.status == 'draft')?_c('span',{staticClass:"p-2",style:('background: #E8ECEF !important;borderRadius: 6px;')},[_vm._v(_vm._s("Draft"))]):_vm._e(),(slotProps.data.status == 'approved')?_c('span',{staticClass:"p-2",style:('background: #F4FBEF !important;borderRadius: 6px;color: #6dd230')},[_vm._v(_vm._s("Active"))]):_vm._e(),(slotProps.data.status == 'complete')?_c('span',{staticClass:"p-2",style:('background: #E8ECEF !important;borderRadius: 6px;')},[_vm._v(_vm._s("Completed"))]):_vm._e()])]),(slotProps.data.invoiced_amount)?_c('div',{staticClass:"surface-300 border-round overflow-hidden w-full mt-1 mr-3",staticStyle:{"height":"6px"}},[_c('div',{staticClass:"h-full",style:(_vm.getProgressStyle(slotProps.data))})]):_vm._e()])]}}],null,true)}),_c('Column',{attrs:{"footerStyle":{
                  backgroundColor: 'white',
                },"headerClass":"header-right","header":_vm.minimized.indexOf(u.uid) < 0 ? '...' : '',"field":"active","styles":{ width: '5%', verticalAlign: 'top !important' }},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('div',[_c('AInputSwitch',{staticClass:"green",attrs:{"value":slotProps.data.is_approved},on:{"input":function($event){return _vm.updateItemStatus(slotProps.data)}}})],1)]}}],null,true)}),_c('Column',{attrs:{"footerStyle":{
                  backgroundColor: 'white',
                },"bodyStyle":"text-align:right","styles":{ width: '1rem', verticalAlign: 'top !important' }},scopedSlots:_vm._u([{key:"header",fn:function(){return [(!_vm.editGroup && !_vm.isLoading)?_c('div',{staticClass:"flex justify-content-end align-items-center cursor-pointer"},[_c('i',{staticClass:"las hover-show la-pen i-20 grey mr-1",on:{"click":function($event){$event.stopPropagation();_vm.editGroup = u.uid;
                        _vm.editGroupTitle = u.title;}}}),_c('i',{staticClass:"las la-trash i-20 grey hover-show",on:{"click":function($event){$event.stopPropagation();return _vm.deletePrompt(u.title, '/v1/tasks/group/' + u.uid)}}})]):_vm._e()]},proxy:true},{key:"body",fn:function(slotProps){return [(_vm.isLoading)?_c('Skeleton'):_c('div',{staticClass:"flex invisible justify-content-end align-items-center cursor-pointer"},[(slotProps.data.task_type_name)?_c('i',{staticClass:"las la-pen i-20 grey mr-1",on:{"click":function($event){$event.stopPropagation();return _vm.navEditMode(slotProps.data)}}}):_c('i',{staticClass:"las la-pen i-20 grey mr-1",on:{"click":function($event){$event.stopPropagation();_vm.$store.dispatch('setSelectedObject', {
                          job: _vm.job,
                          group: u,
                          cost_uid: slotProps.data.uid,
                          mode: 'edit',
                        });
                        _vm.$store.dispatch('setSliderView', 'JobCostSlider');}}}),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                        value: 'Item has tracked time',
                        disabled:
                          !slotProps.data.hours ||
                          slotProps.data.hours === '00:00',
                      }),expression:"{\n                        value: 'Item has tracked time',\n                        disabled:\n                          !slotProps.data.hours ||\n                          slotProps.data.hours === '00:00',\n                      }",modifiers:{"bottom":true}}],class:'las la-trash i-20 ' +
                        (slotProps.data.hours &&
                        slotProps.data.hours !== '00:00'
                          ? 'disable-grey cursor-disable'
                          : 'grey'),on:{"click":function($event){$event.stopPropagation();slotProps.data.hours &&
                        slotProps.data.hours !== '00:00'
                          ? () => {}
                          : _vm.deletePrompt(
                              _vm.getItemTitle(slotProps.data),
                              '/v1/' +
                                (slotProps.data.task_type_name
                                  ? 'tasks'
                                  : 'costs') +
                                '/' +
                                slotProps.data.uid
                            )}}})])]}}],null,true)})],1):_vm._e()],1)]):_vm._e()]):_vm._e()])}),0),(_vm.jobItemTotals)?_c('TotalTableFooter',{attrs:{"subTotal":_vm.jobItemTotals.sub,"taxRate":parseFloat(_vm.jobItemTotals.tax),"profitMargin":_vm.jobItemTotals.margin,"total":_vm.jobItemTotals.total,"currencySymbol":_vm.job?.client?.currency?.description}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }