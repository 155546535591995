<template>
  <div align="left">
    <div class="card">
      <div class="flex justify-content-between pb-2">
        <div class="col flex mb-1">
          <i
            @click="closeInvoice"
            style="color: #778ca2"
            class="las text-20 la-angle-left pt-1 pr-1 bluehover cursor-pointer"
          />
          <h1 class="flex align-items-center mr-3 font-light">
            Invoice:&nbsp;
            <!-- Place holder for invoice number -->
            <!-- stateInvoice : {{ JSON.stringify(stateInvoice) }} -->
            <h1 v-if="!isLoading">
              &nbsp;{{ stateInvoice?.invoice?.document_number }}
            </h1>
            <Skeleton width="100px" height="24px" borderRadius="6px" v-else />
            <span class="text-12 pl-4 mr-3"
              ><span
                v-if="!isLoading"
                :style="getTagStyle() + 'borderRadius: 4px'"
                class="p-2 py-1 uppercase"
              >
                <!-- Place holer for invoice status -->
                {{ stateInvoice?.invoice?.status }}</span
              >
              <Skeleton width="52px" height="21px" borderRadius="4px" v-else
            /></span>
          </h1>
        </div>
        <div class="flex text-center align-items-center">
          <div class="pr-3">
            <i
              class="las la-pen grey i-20 cursor-pointer"
              @click="
                $store.dispatch('setSelectedObject', stateInvoice.invoice);
                $store.dispatch('setSliderView', 'InvoiceSlider');
              "
              v-if="stateInvoice?.invoice?.status !== 'issued' && !isLoading"
            />
          </div>
          <div>
            <Skeleton
              height="40px"
              width="125px"
              borderRadius="6px"
              v-if="isLoading"
            />
            <Button
              v-else
              icon="las la-plus"
              label="Add Items"
              class="p-button-text p-button-secondary button-outline"
              @click="displayAddItems = true"
            />
          </div>

          <div class="ml-3">
            <Skeleton
              height="40px"
              width="80px"
              borderRadius="6px"
              v-if="isLoading"
            />
            <template v-else>
              <Button
                v-if="stateInvoice?.invoice?.status === 'issued'"
                class="p-button-warning"
                label="Issued"
                disabled />
              <Button
                v-else-if="getStateInvoiceLength(stateInvoice) > 0"
                :loading="isIssueLoading"
                icon="las la-paper-plane"
                label="Issue"
                @click="issueInvoice()"
            /></template>
          </div>
        </div>
      </div>
      <Divider class="mt-0 mb-2"></Divider>
      <div class="pt-1">
        <div class="p-fluid formgrid grid">
          <div class="col-12 md:col-12 lg:col-12 xl:col-12">
            <div class="p-fluid formgrid grid justify-content-start p-2">
              <div class="field col-4 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <label class="font-light">Title</label>
                <!-- Placeholder for invoice title -->
                <div v-if="!isLoading">{{ stateInvoice?.invoice?.name }}</div>
                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
              <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <label class="font-light">Issue Date</label>
                <!-- Placeholder for issue date -->
                <div v-if="!isLoading">
                  {{ formatDate(stateInvoice?.invoice.date_issued) }}
                </div>
                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
              <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <label class="font-light">Due Date</label>
                <!-- Placeholder for due date -->
                <div v-if="!isLoading">
                  {{ formatDate(stateInvoice?.invoice.date_due) }}
                </div>
                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
              <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <label class="font-light">Terms</label>
                <!-- Placeholder for terms -->
                <div v-if="!isLoading">Cash on Delivery</div>
                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
              <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <label class="font-light">PO Number</label>
                <!-- Placeholder for terms -->
                <div v-if="!isLoading">N/A</div>
                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
              <div class="field col-3 sm:col-6 md:col-4 lg:col-4 xl:col-3">
                <label class="font-light">Progress Invoice</label>
                <!-- Placeholder for terms -->
                <div v-if="!isLoading">
                  {{ stateInvoice?.invoice.progress_percentage }} %
                </div>
                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
              <div class="field col-12">
                <label class="font-light">Description</label>
                <!-- Placeholder for description -->
                <div v-if="!isLoading">
                  {{ stateInvoice?.invoice.description }}
                </div>
                <Skeleton height="18px" borderRadius="6px" v-else />
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex justify-content-between align-items-center my-3"
          style="font-size: 18px"
        >
          <div>
            <p class="p-t-12 p-2" style="font-size: 18px">Tasks &#38; Costs</p>
          </div>
        </div>
        <div v-if="isLoading">
          <Skeleton
            class="mb-3"
            v-for="i in 4"
            :key="i"
            height="36px"
            borderRadius="6px"
          />
        </div>
        <template v-else-if="stateInvoice?.items < 1">
          <div
            style="padding-top: 48px; padding: 80px; font-size: 16px"
            align="center"
            v-if="
              (jobItems?.filter((g) => g.tasks.length > 0).length < 1 &&
                jobItems?.filter((g) => g.costs.length > 0).length < 1) ||
              typeItems?.length < 1
            "
          >
            <div
              v-if="determinedTotal(jobItems)"
              style="
                display: inline-block;
                padding: 30px 80px;
                background: #f8fafb;
                border-radius: 100px;
              "
            >
              No billable items to add
            </div>
            <div
              v-else
              style="
                display: inline-block;
                padding: 30px 80px;
                background: #f8fafb;
                border-radius: 100px;
              "
              @click="displayAddItems = true"
              class="cursor-pointer"
            >
              <a>Add</a>
              billable items
            </div>
          </div>
          <!-- <div
            class="cursor-pointer"
            v-if="getStateInvoiceLength(stateInvoice) > 0"
            style="padding-top: 48px; padding: 80px; font-size: 16px"
            align="center"
            @click="displayAddItems = true"
          >
            <div
              style="
                display: inline-block;
                padding: 30px 80px;
                background: #f8fafb;
                border-radius: 100px;
              "
            >
              <a>Add</a>
              billable items
            </div>
          </div> -->
        </template>
        <template v-else>
          <InvoiceTaskCost
            :groups="typeItems"
            :setCurrencySymbol="setCurrencySymbol"
            @dismiss="displayAddItems = false"
            :invoiceId="stateInvoice?.invoice?.uid"
            :canSelect="false"
          ></InvoiceTaskCost>
        </template>
      </div>

      <TotalTableFooter
        :subTotal="stateInvoice?.totals?.sub"
        :taxRate="parseFloat(stateInvoice?.totals?.tax)"
        :profitMargin="stateInvoice?.totals?.margin"
        :total="stateInvoice?.totals?.total"
        :currencySymbol="job?.client?.currency?.description"
        v-if="stateInvoice?.totals"
        :canSelect="true"
      ></TotalTableFooter>
    </div>
    <Dialog
      header="Select items"
      contentStyle="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;max-width:1400px"
      :visible.sync="displayAddItems"
      :modal="true"
      :closable="true"
      :dismissableMask="true"
    >
      <template v-if="jobItems">
        <InvoiceTaskCost
          :groups="jobItems"
          @dismiss="displayAddItems = false"
          :setCurrencySymbol="setCurrencySymbol"
          :invoiceId="stateInvoice?.invoice?.uid"
          notInvoiced
        ></InvoiceTaskCost
      ></template>
    </Dialog>
  </div>
</template>

<script>
import {
  state,
  fetchInvoice,
  fetchJobItems,
} from "../../../services/data_service.js";
import TotalTableFooter from "@/application/Finance/components/TotalTableFooter.vue";
import InvoiceTaskCost from "@/application/Finance/components/InvoiceTaskCost.vue";
import Vue from "vue";
export default {
  data() {
    return {
      displayAddItems: false,
      isLoading: false,
      isIssueLoading: false,
      invoice: null,
      items: null,
      setCurrencySymbol: null,
      taxRate: null,
      profitMargin: null,
      status: null,
    };
  },

  beforeMount() {
    state.invoice = null;
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    this.isLoading = true;
    await this.loadData();
    this.isLoading = false;
  },
  computed: {
    uid() {
      return this.$route.params.invoice_uid;
    },

    stateInvoice() {
      return state.invoice;
    },
    subTotal() {
      var s = 0;
      this.stateInvoice?.items.forEach((g) => {
        g.tasks.forEach((t) => {
          s += parseFloat(t.total);
        });
        g.costs.forEach((t) => {
          s += parseFloat(t.total);
        });
      });
      return s;
    },
    jobItems() {
      return state.jobItems;
    },
    job() {
      return state.job;
    },
    typeItems() {
      return this.stateInvoice?.items?.map((item) => {
        const tasksWithType = (item.tasks || []).map((task) => ({
          ...task,
          type: "task",
        }));
        const costsWithType = (item.costs || []).map((cost) => ({
          ...cost,
          type: "cost",
        }));

        return {
          ...item,
          tasks: tasksWithType,
          costs: costsWithType,
        };
      });
    },
  },
  methods: {
    determinedTotal(jobItems) {
      var c = 0;

      return c > 0;
    },
    getStateInvoiceLength(stateInvoice) {
      return stateInvoice?.items.length;
    },
    issueInvoice() {
      this.$confirm.require({
        message: "Are you sure you want to issue this invoice?",
        header: "Confirm",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.isIssueLoading = true;
          this.$axios
            .get(
              process.env.VUE_APP_ROOT_API +
                "/v1/finance/invoice/issue/" +
                this.uid,
              {}
            )
            .then((response) => {
              this.$bus.emit("refreshData");
              this.$toast.add({
                severity: "success",
                summary: "Invoice Issued",
                life: 3000,
              });
              this.isIssueLoading = false;
            })
            .catch((error) => {
              console.error(error);
              this.isIssueLoading = false;
            });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    formatDate(date) {
      return this.$moment(date).format("DD MMM YY");
    },
    getTagStyle() {
      switch (this.stateInvoice?.invoice?.status) {
        case "draft":
          return "background: #B9C5D0 !important;color: #252631 !important;";
        case "issued":
          return "background: #f59e0b !important;color: #ffffff !important;";

        default:
          return "background: #F8FAFB !important;color: #778CA2 !important;";
      }
    },
    async loadData() {
      await Promise.all([
        fetchInvoice(this.uid),
        fetchJobItems(this.$route.params.uid, this.uid),
      ]);
    },
    closeInvoice() {
      this.$router.push({ name: "job--financials" });
    },
    addType(items) {
      items?.forEach((i) => {
        i.tasks?.forEach((t) => {
          t.type = "task";
        });
        i.costs?.forEach((c) => {
          c.type = "cost";
        });
      });
      return items;
    },
  },
  watch: {},

  metaInfo() {
    return {
      title: "Invoice",
      meta: [{ vmid: "description", name: "description", content: "" }],
    };
  },
  name: "Invoice",
  components: { TotalTableFooter, InvoiceTaskCost },
};
</script>
<style>
.bluehover:hover {
  color: #4d7cfe !important;
}
</style>