<template>
  <div class="layout-topbar justify-content-start">
    <button
      class="p-link layout-menu-button layout-topbar-button justify-content-center text-primary"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars" />
    </button>

    <div
      :class="['flex', { 'menu-inactive': staticMenuInactive }]"
      v-if="account"
    >
      <div
        class="col static-menu"
        style="font-size: 16px"
        :style="{
          'margin-left': staticMenuInactive ? '30px' : '180px',
          transition: 'margin-left 0.3s ease',
        }"
      >
        <ProfileSwitch></ProfileSwitch>
      </div>
    </div>
    <ul
      class="layout-topbar-menu hide-layout-topbar-menu-items flex origin-top"
    >
      <li v-if="timer_on" class="flex align-items-center pr-2">
        <div class="las la-stopwatch pr-1 time-icon" />
        <div
          style="cursor: pointer"
          @click="navigateMyDesk()"
          class="p-text-green"
        >
          {{ secToHMS(currentTimer) }}
        </div>
      </li>
      <!-- <li class="flex align-items-center pr-2 pl-3">
        <div class="las la-plus-circle i-20 search-field"></div>
      </li>
      <li class="flex align-items-center pr-1 pl-3">
        <div class="las la-search i-20 search-field"></div>
      </li> -->
      <li @click="toggle" class="flex align-items-center pl-3 pr-2">
        <!-- Notification button -->

        <i
          class="las la-bell i-20 font-light cursor-pointer notification-bell pt-2"
          @click="
            $store.dispatch('setSliderView', 'NotificationSlider');
            $store.dispatch('setSelectedObject', null);
          "
          ><span
            v-if="unreadNotifications > 0"
            class="p-badge p-component p-badge-warning p-badge-no-gutter"
            >{{ unreadNotifications }}</span
          >
          <span
            v-else
            class="p-badge p-component p-badge-no-gutter p-badge-hidden"
          >
          </span>
        </i>

        <Avatar
          style="width: 44px; height: 44px"
          class="cursor-pointer profile-border"
          :image="user?.profile_picture"
          :label="!user?.profile_picture ? getIntials(user) : null"
          :style="
            !user?.profile_picture
              ? {
                  'background-color': getColorFromName(user),
                  color: '#ffffff',
                }
              : ''
          "
          shape="circle"
        />
      </li>
    </ul>
    <Menu class="mt-2 profile-menu" ref="menu" :model="items" :popup="true" />
  </div>
</template>

<script>
import { fetchNotifications, state } from "../services/data_service";
import ProfileSwitch from "../components/ProfileSwitch.vue";
export default {
  components: {
    ProfileSwitch,
  },
  data() {
    return {
      items: [
        {
          label: "Update my Status",
          icon: "las la-smile",
        },
        {
          label: "Edit my Details",
          icon: "las la-pen",
          command: () => {
            this.$store.dispatch("setSelectedObject", this.user.uid);
            this.$store.dispatch("setSliderView", "MyDetailsSlider");
          },
        },
        {
          label: "Log Out",
          icon: "las la-sign-out-alt",
          command: () => {
            this.$store.dispatch("logout");
          },
        },
      ],
    };
  },
  mounted() {
    fetchNotifications();
  },
  methods: {
    navigateMyDesk() {
      this.$router.push("/desk");
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    topbarImage() {
      return "@/assets/agencydesk-rocket.svg";
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
  },
  computed: {
    notificationCount() {
      return state.notifications;
    },
    unreadNotifications() {
      return state.notifications.filter((n) => n.is_read !== 1)?.length;
    },
    user() {
      return this.$store.getters.user;
    },
    account() {
      return state.accounts;
    },
    timer() {
      return this.$store.getters.timer;
    },
    timer_active() {
      return this.timer && this.timer.active;
    },
    timer_on() {
      return this.timer_active === 1;
    },
    currentTimer() {
      return this.$store.getters.currentTimer;
    },
    staticMenuInactive() {
      return this.$store.getters.staticMenuInactive;
    },
  },
};
</script>

<style>
.notification-bell > .p-badge {
  top: -12px !important;
  left: -7px !important;
  font-size: 0.6rem !important;
  font-weight: 500 !important;
  min-width: 1rem !important;
  height: 1rem !important;
  line-height: 1rem !important;
  position: relative;
}

.notification-bell > .p-badge-hidden {
  visibility: hidden !important;
}

.time-icon {
  color: #4dd230;
}
.search-field {
  color: white;
}
.profile-border {
  border: solid;
  color: white;
  border-width: 1.8px;
}
.profile-menu {
  top: 42px !important;
}

@media screen and (max-width: 680px) {
  .static-menu {
    margin-left: 0px !important;
  }
}
</style>


