import Vue from "vue";
import store from "@/store";
const initialState = {
  mustRefresh: true,
  accountIndustries: [ {
    uid: 'dm',
    name: 'Digital Marketing'
  },
  {
    uid: 'adv',
    name: 'Advertising & Marketing'
  },
  {
    uid: 'cre',
    name: 'Creative & Branding'
  },
  {
    uid: 'wad',
    name: 'Web and App Development'
  },
  {
    uid: 'pid',
    name: 'Product & Industrial Design'
  },
  {
    uid: 'prc',
    name: 'Public Relations & Communications'
  },
  {
    uid: 'cmp',
    name: 'Creative Media & Production'
  }
  ],
  accountSuppliers: [],
  accounts: {
    uid: "",
    name: "",
    display_name: "",
    company_registration: "",
    logo_url: "",
    tel_number: "",
    email: "",
    website: "",
    address_physical: null,
    address_postal: null,
    finance: {
      tax_abbreviation: "",
      tax_number: "",
      tax_rate: "",
      banking_details: "",
      cost_estimate_footer: "",
      cost_estimate_prefix: "",
      cost_estimate_numbering_start: "",
      invoice_footer: "",
      invoice_prefix: "",
      invoice_numbering_start: "",
      purchase_order_footer: "",
      purchase_order_prefix: "",
      purchase_order_numbering_start: "",
      minimum_time_increment: ""
    },
    industry: {
      uid: "",
      name: ""
    },
    currency: {
      uid: "",
      name: ""
    }
  },
  accountFinance: null,
  amendments: [],
  approvalStatuses: null,
  billingTiers: null,
  captured_times: [],
  categories: null,
  contactTitles: [],
  costType: null,
  costTypes: [],
  currencies: null,
  client: null,
  clients: null,
  clientsSummary: null,
  currencyConversion: null,
  clientCredits: [],
  clientTerms: [],
  notifications: [],
  costTypesAndOptions: [],
  cost: null,
  taskType: null,
  departments: null,
  estimate: null,
  editTaskMode: false,
  estimateCategories: [],
  estimateClients: [],
  estimateTerms: [],
  estimates: null,
  estimatesSummary: null,
  industryDepartments: [],
  industryRoles: [],
  industryTaskTypes: [],
  invoice: null,
  invoices: null,
  isPaused: null,
  job: null,
  jobs: [],
  jobCosts: null,
  jobDocuments: null,
  jobEstimate: null,
  jobItems: null,
  jobsStatus: null,
  jobItemTotals: null,
  jobNotes: null,
  myDesk: null,
  myTasks: null,
  notes: [],
  pausedTasks: [],
  permissionsProfiles: [],
  accountRoles: [],
  roleIndustries: [],
  rolesDepartment: [],
  selectedJobUid: null,
  selectedTask_uid: null,
  selectedContactDetail_uid: null,
  selectedCosts_uid: null,
  settingsUsers: null,
  suppliers: null,
  supplier: null,
  selectedClientUid: null,
  selectedCostEstimateUid: null,
  selectedSupplierUid: null,
  supplierCategories: [],
  supplierCredits: [],
  supplierPurchases: [],
  supplierTerms: [],
  task: null,
  contactDetail: null,
  itemGroup: null,
  taskSchedule: null,
  taskTags: null,
  taskTypePricing: [{
    uid: 'tfp',
    name: 'Fixed price',
    category: 'task'
  }, {
    uid: 'rph',
    name: 'Rate per hour',
    category: 'task'
  }, {
    uid: 'cfp',
    name: 'Fixed price',
    category: 'cost'
  }, {
    uid: 'per',
    name: 'Percentage',
    category: 'cost'
  }],
  taskTypes: null,
  taskUsers: [],
  tasks: [],
  tasksJob: null,
  templates: null,
  templateToEstimate: null,
  userTasks: null,
  user: null,
  users: [],
  usersData: [],
};

// Reactive state
export const state = Vue.observable(initialState);


// Fetch user tasks
export const fetchMyTasks = async () => {
  try {
    const response = await Vue.prototype.$axios.get("/v1/tasks", {
      params: { my: 1 },
    });

    if (
      response.data.data.timers &&
      response.data.data.timers.length > 0 &&
      response.data.data.timers[0].length > 0
    ) {
      state.pausedTasks = response.data.data.timers[0].filter((a) => !!a.title);
    } else {
      state.pausedTasks = [];
    }
    state.myTasks = response.data.data;

    var myDesk =
      state.myTasks.desk && state.myTasks.desk[0]
        ? state.myTasks.desk[0]
        : null;

    if (myDesk !== null) {
      for (var arr in state.myTasks) {
        for (let index = 0; index < state.myTasks[arr].length; index++) {
          const element = state.myTasks[arr][index];
          if (element.uid === myDesk.uid && element.timer_on) {
            state.isPaused = element.timer_on !== "1";
          }
        }
      }
    }
    state.myDesk = state.myTasks.desk[0];
  } catch (e) {
    console.log(e);
  }
};

// Fetch user
export const fetchUser = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/users/" + store.getters.user.uid,
      {}
    );
    store.commit("setUserPermissions", response.data.data);
    state.user = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch task tags
export const fetchTaskTags = async () => {
  try {
    const response = await Vue.prototype.$axios.get("/v1/tasktag", {});
    state.taskTags = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchNotifications = async () => {
  try {
    const response = await Vue.prototype.$axios.get("/v1/accounts/notifications", {});
    state.notifications = response.data.data;
  } catch (e) {
    console.log(e);
  }
}


export const clearNotifications = async () => {
  try {
    await Vue.prototype.$axios.get("/v1/accounts/notifications/clear", {});

  } catch (e) {
    console.log(e);
  }
}

// Fetch users
export const fetchUsers = async () => {
  try {
    const response = await Vue.prototype.$axios.get("/v1/users", {});
    state.users = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchSettingsUsers = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/users/settings/" +
      store.getters.user.account_uid,
      {}
    );
    state.settingsUsers = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch users witrh tasks too
export const fetchUserTasks = async (keepOrder,timeSheet = null,date_from = null,date_to = null) => {
  try {

    var url = "/v1/users?showTasks=1"

    if(timeSheet){
      url = url + "&timeSheet=1"
    }
    if(date_from){
      url = url + "&date_from=1"
    }
    if(date_to){
      url = url + "&date_to=1"
    }
    

    const response = await Vue.prototype.$axios.get(
      url,
      {}
    );

    var userTasks  = response.data.data;

    userTasks.forEach((u) => {
      u.tasks = u.tasks.filter((t) => t.is_complete !== 1);
    });
    
    userTasks.forEach((u) => {
      u.tasks.forEach((t) => {
        if (t.hours_planned > 0 || t.minutes_planned > 0) {
          t.hasPlanned = true;
        } else {
          t.hasPlanned = false;
        }
      });
    })

    if(keepOrder ===true){
      userTasks.forEach(user => {
        var userTask = state.userTasks.find(a => a.uid === user.uid);
        if(userTask){
          user.tasks?.forEach(task => {
            var taskIndex = userTask.tasks.findIndex(a => a.uid === task.uid);
            if(taskIndex > -1){
              userTask.tasks[taskIndex] = task;
            }else{
              userTask.tasks.push(task);
            }
          });
        }
      })

    }else{
      state.userTasks = userTasks;
    }

  } catch (e) {
    console.log(e);
  }
};

// Fetch tasks
export const fetchTasks = async () => {
  try {
    const response = await Vue.prototype.$axios.get("/v1/tasks", {});
    state.tasks = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch desk and update the state
export const fetchMyDesk = async () => {
  try {
    const response = await Vue.prototype.$axios.get("/v1/tasks", {
      params: { desk: 1 },
    });
    state.myDesk = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch departments
export const fetchDepartments = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/departments/" +
      store.getters.user.account_uid,
      {}
    );
    state.departments = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch accounts
export const fetchAccounts = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/accounts/" +
      store.getters.user.account_uid,
      {}
    );

    if(response.data.data.uid !== state.accounts.uid){
      state.accounts = response.data.data;
    } else {
      state.accounts = { ...state.accounts, ...response.data.data };
    }



    if (state?.accounts?.finance) {
      store.dispatch('setAccountFinance', state.accounts.finance)
    }


    store.dispatch('setIntegrationId',state.accounts.integration_id)
    
  } catch (e) {
    console.log(e);
  }
};

// Fetch account  fiances
export const fetchAccountFinance = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/departments/gls",
      {}
    );
    state.accountFinance = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch account approval statuses
export const fetchApprovalStatuses = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/jobs/list/approval/status/" +
      store.getters.user.account_uid,
      {}
    );
    state.approvalStatuses = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch accounts
export const fetchCurrency = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API + "/v1/accounts/currencies/",
      {}
    );
    state.currencies = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch accounts
export const fetchAccountRoles = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API + "/v1/roles/list/" + store.getters.user.account_uid,
      {}
    );
    state.accountRoles = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch accounts
// export const fetchAccountIndustries = async () => {
//   try {
//     const response = await Vue.prototype.$axios.get(
//       process.env.VUE_APP_ROOT_API + "/v1/accounts/industries/",
//       {}
//     );
//     state.accountIndustries = response.data.data;
//   } catch (e) {
//     console.log(e);
//   }
// };

// Fetch accounts industry departments
export const fetchIndustryDepartments = async (industry_uid) => {
  try {
    // const response = await Vue.prototype.$axios.get(
    //   process.env.VUE_APP_ROOT_API +
    //   "/v1/accounts/industry/departments/" +
    //   industry_uid,
    //   {}
    // );

// Return these departments
//     Digital Marketing
// Advertising & Marketing
// Creative & Branding
// Web and App Development
// Product & Industrial Design
// Public Relations & Communications
// Creative Media & Production

    state.industryDepartments = [
      {
        uid: 'dm',
        name: 'Digital Marketing'
      },
      {
        uid: 'adv',
        name: 'Advertising & Marketing'
      },
      {
        uid: 'cre',
        name: 'Creative & Branding'
      },
      {
        uid: 'wad',
        name: 'Web and App Development'
      },
      {
        uid: 'pid',
        name: 'Product & Industrial Design'
      },
      {
        uid: 'prc',
        name: 'Public Relations & Communications'
      },
      {
        uid: 'cmp',
        name: 'Creative Media & Production'
      }
    ];
  } catch (e) {
    console.log(e);
  }
};

export const fetchAccountIndustries = async () => {
  try {
    // const response = await Vue.prototype.$axios.get(
    //   process.env.VUE_APP_ROOT_API + "/v1/accounts/industries/",
    //   {}
    // );
    // state.accountIndustries = response.data.data;

    state.industryDepartments = [
      {
        uid: 'dm',
        name: 'Digital Marketing'
      },
      {
        uid: 'adv',
        name: 'Advertising & Marketing'
      },
      {
        uid: 'cre',
        name: 'Creative & Branding'
      },
      {
        uid: 'wad',
        name: 'Web and App Development'
      },
      {
        uid: 'pid',
        name: 'Product & Industrial Design'
      },
      {
        uid: 'prc',
        name: 'Public Relations & Communications'
      },
      {
        uid: 'cmp',
        name: 'Creative Media & Production'
      }
    ];
  } catch (e) {
    console.log(e);
  }
};

// Fetch industry roles
export const fetchIndustryRoles = async (industry_uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/roles/industry/" + industry_uid,
      {}
    );
    state.industryRoles = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch roles department for account
export const fetchRolesDepartment = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/roles/department/" +
      store.getters.user.account_uid,
      {}
    );

    state.rolesDepartment = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch permission profiles for account
export const fetchPermissionProfiles = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/users/permissionprofiles/" +
      store.getters.user.account_uid,
      {}
    );
    state.permissionsProfiles = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch task types for account
export const fetchTaskTypes = async (is_template) => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/tasktype/list/account/" +
      store.getters.user.account_uid + "/" + is_template,
      {}
    );
    state.taskTypes = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch tasktype for industry
export const fetchIndustryTaskTypes = async (industry_uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/tasktype/list/industry/" +
      industry_uid,
      {}
    );
    state.industryTaskTypes = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch tasktype pricing
export const fetchTaskTypePricing = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/tasktype/list/pricing",
      {}
    );
    state.taskTypePricing = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch billingtier for account
export const fetchBillingTiers = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/tasktype/list/billingtier/" +
      store.getters.user.account_uid,
      {}
    );
    state.billingTiers = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch categories for account
export const fetchCategories = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/jobs/category/list/" +
      store.getters.user.account_uid,
      {}
    );
    state.categories = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch cost for job
export const fetchCost = async (cost_uid) => {
  try {
    if (cost_uid) {
      state.selectedCost_uid = cost_uid;
    }
    const response = await Vue.prototype.$axios.get(
      "/v1/costs/" + state.selectedCost_uid,
      {}
    );
    if (!response.data.data.tags) {
      response.data.data.tags = []
    }
    state.cost = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch tasks for job
export const fetchTasksJob = async (job_uid, is_approved = null) => {
  try {
    if (job_uid) {
      if (state.selectedJobUid !== job_uid) {
        state.selectedJobUid = job_uid;
      }
    }
    var url = "/v1/jobs/" + state.selectedJobUid + "/tasks"

    if (is_approved !== null) {
      url = url + "?is_approved=" + is_approved
    }
    const response = await Vue.prototype.$axios.get(
      url,
      {}
    );
    state.tasksJob = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchContactDetails = async (contactDetail_uid) => {
  try {
    if (!contactDetail_uid && !state.selectedContactDetail_uid) {
      return
    }
    if (contactDetail_uid) {
      state.selectedContactDetail_uid = contactDetail_uid;
    }
    const response = await Vue.prototype.$axios.get(
      "/v1/accounts/contacts/" + state.selectedContactDetail_uid,
      {}
    );
    state.contactDetail = response.data.data;
  } catch (e) {
    state.contactDetail = null
    console.log(e);
  }
};
// Fetch task by uid
export const fetchTask = async (task_uid) => {
  try {
    if (!task_uid && !state.selectedTask_uid) {
      return
    }
    if (task_uid) {
      state.selectedTask_uid = task_uid;
    }
    const response = await Vue.prototype.$axios.get(
      "/v1/tasks/" + state.selectedTask_uid,
      {}
    );
    if (!response.data.data.tags) {
      response.data.data.tags = []
    }
    state.task = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch task by uid
export const fetchTaskUsers = async (task_uid) => {
  try {
    if (task_uid) {
      state.selectedTask_uid = task_uid;
    }

    const response = await Vue.prototype.$axios.get("/v1/users/task", {
      params: {
        task: task_uid,
      },
    });
    state.taskUsers = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch task by uid
export const fetchJob = async (job_uid) => {
  try {
    if (job_uid) {
      if (state.selectedJobUid !== job_uid) {
        state.selectedJobUid = job_uid;
      }
    }
    const response = await Vue.prototype.$axios.get(
      "/v1/jobs/" + state.selectedJobUid,
      {}
    );
    state.job = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchJobCosts = async (job_uid, is_approved = null) => {
  try {
    if (job_uid) {
      if (state.selectedJobUid !== job_uid) {
        state.selectedJobUid = job_uid;
      }
    }

    var url = "/v1/jobs/" + state.selectedJobUid + "/costs"

    if (is_approved !== null) {
      url = url + "?is_approved=" + is_approved
    }
    const response = await Vue.prototype.$axios.get(
      url,
      {}
    );
    state.jobCosts = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchJobEstimate = async (job_uid) => {
  try {
    if (job_uid) {
      if (state.selectedJobUid !== job_uid) {
        state.selectedJobUid = job_uid;
      }
    }
    const response = await Vue.prototype.$axios.get(
      "/v1/jobs/estimate/" + state.selectedJobUid,
      {}
    );
    state.jobEstimate = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchJobItems = async (job_uid, invoice_uid = null) => {
  try {
    if (job_uid) {
      if (state.selectedJobUid !== job_uid) {
        state.selectedJobUid = job_uid;
      }
    }
    const response = await Vue.prototype.$axios.get(
      "/v1/jobs/" + state.selectedJobUid + "/items" + (invoice_uid ? '?invoice=' + invoice_uid : ''),
      {}
    );
    state.jobItems = response.data.data;
    state.jobItemTotals = response.data.totals;
  } catch (e) {
    console.log(e);
  }
};

export const fetchJobNotes = async (job_uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/jobs/" + job_uid + "/notes",
      {}
    );
    state.jobNotes = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchJobDocuments = async (job_uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/jobs/" + job_uid + "/files",
      {}
    );
    state.jobDocuments = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch Cost Estimate by uid
export const fetchCostEstimate = async (ce_uid) => {
  try {
    if (ce_uid) {
      state.selectedCostEstimateUid = ce_uid;
    }
    const response = await Vue.prototype.$axios.get(
      "/v1/estimate/" + state.selectedCostEstimateUid,
      {}
    );
    state.estimate = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch Cost Types and Options by Estimate uid
export const fetchCostTypeAndOptions = async (ce_uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/estimate/get/costtypeoptions/" +
      ce_uid,
      {}
    );
    state.costTypesAndOptions = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch Client by uid
export const fetchClient = async (client_uid) => {
  try {
    if (client_uid) {
      state.selectedClientUid = client_uid;
    }
    const response = await Vue.prototype.$axios.get(
      "/v1/clients/" + state.selectedClientUid,
      {}
    );

    response.data.data.contacts = response.data?.data?.contacts.filter(a => a)
    state.client = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch Client by uid
export const fetchSupplier = async (uid) => {
  try {
    if (uid) {
      state.selectedSupplierUid = uid;
    }
    const response = await Vue.prototype.$axios.get(
      "/v1/costtype/view/supplier/" + state.selectedSupplierUid,
      {}
    );
    state.supplier = response.data.data;
  } catch (e) {
    console.log(e);
  }
};


//Supplier
export const fetchSuppliers = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/costtype/list/supplier/" + store.getters.user.account_uid,
      {}
    );
    state.suppliers = response.data.data;
  } catch (e) {
    console.log(e);
  }
};
export const fetchSupplierCategories = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/costtype/list/supplier/categories/" + store.getters.user.account_uid,
      {}
    );
    state.supplierCategories = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchSupplierCredits = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/costtype/list/supplier/credits/" + store.getters.user.account_uid,
      {}
    );
    state.supplierCredits = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchSupplierPurchases = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/costtype/list/supplier/purchases/" + store.getters.user.account_uid,
      {}
    );
    state.supplierPurchases = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchSupplierTerms = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/costtype/list/supplier/terms/" + store.getters.user.account_uid,
      {}
    );
    state.supplierTerms = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

//Contact titles
export const fetchContactTitles = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/costtype/list/supplier/contacttitles/" +
      store.getters.user.account_uid,
      {}
    );
    state.contactTitles = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Fetch task by uid
export const fetchJobs = async (is_accepted = null) => {
  try {

    var url = "/v1/jobs"

    if (is_accepted !== null) {
      url = url + "?is_accepted=" + is_accepted
    }

    const response = await Vue.prototype.$axios.get(url, {});
    state.jobs = response.data.data;
    state.templates = state.jobs.filter((job) => job.is_template === 1);
  } catch (e) {
    console.log(e);
  }
};

export const fetchJobsStatus = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/jobs/status/list/" + store.getters.user.account_uid,
      {}
    );
    state.jobsStatus = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchCurrencyConversion = async (amount, from, to) => {
  if (to == from) {
    state.currencyConversion = amount
  }

  try {
    const response = await Vue.prototype.$axios.get(
      "/api/currency/convert?amount=" + amount + "&from=" + from + "&to=" + to,
      {}
    );

    state.currencyConversion = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchClientsFull = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/clients/listfull/" + store.getters.user.account_uid,
      {}
    );
    state.clients = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchClients = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/clients",
      {}
    );

    state.clients = response.data.data;
  } catch (e) {
    console.log(e);
  }
};


export const fetchClientsSummary = async (isTemp =false) => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/clients/listsummary/" + store.getters.user.account_uid + (isTemp ? "?temp=1" : ""),
      {}
    );

    state.clientsSummary = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchClientCredits = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/clients/list/credits/" + store.getters.user.account_uid,
      {}
    );

    state.clientCredits = response.data.data;
  } catch (e) {
    console.log(e);
  }
};
export const fetchClientTerms = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/clients/list/terms/" + store.getters.user.account_uid,
      {}
    );

    state.clientTerms = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchAccountCostTypes = async (is_template) => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/costtype/list/account/" +
      store.getters.user.account_uid + '/' + is_template,
      {}
    );
    state.costTypes = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchAccountSuppliers = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/suppliers",
      {}
    );
    state.accountSuppliers = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchEstimateClients = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/estimate/list/client/" +
      store.getters.user.account_uid,
      {}
    );
    state.estimateClients = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchUsersData = async () => {
  try {
    const response = await Vue.prototype.$axios.get("/v1/users/data", {});
    state.usersData = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchNotes = async (task_uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/tasks/notes?task=" + task_uid,
      {}
    );
    state.notes = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchEstimateCategories = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API +
      "/v1/estimate/list/category/" +
      store.getters.user.account_uid,
      {}
    );
    state.estimateCategories = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchEstimateTerms = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/estimate/list/term/" + store.getters.user.account_uid,
      {}
    );
    state.estimateTerms = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchEstimates = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/estimate/list/" + store.getters.user.account_uid,
      {}
    );
    state.estimates = response.data.data;
    state.templates = state.estimates.filter((estimate) => estimate.is_template === 1);
  } catch (e) {
    console.log(e);
  }
};


export const fetchInvoices = async (job_uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/finance/invoice" + (job_uid ? "?job=" + job_uid : ""),
      {}
    );
    state.invoices = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchInvoice = async (uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/finance/invoice/" + uid,
      {}
    );
    state.invoice = response.data.data;
  } catch (e) {
    console.log(e);
  }
};



export const fetchEstimatesSummary = async () => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/estimate/list/summary/" + store.getters.user.account_uid,
      {}
    );
    state.estimatesSummary = response.data.data;
    state.templates = state.estimatesSummary.filter((estimate) => estimate.is_template === 1);
  } catch (e) {
    console.log(e);
  }
};

// Amendments
export const fetchTaskTimes = async (task_uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API + "/v1/tasks/times?uid=" + task_uid,
      {}
    );
    state.captured_times = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Amendments
export const fetchAmendments = async (task_uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API + "/v1/tasks/amendments?task=" + task_uid,
      {}
    );
    state.amendments = response.data.data;
  } catch (e) {
    console.log(e);
  }
};

// Cancel Time
export const cancelTime = async (uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      "/v1/tasks/" + uid + "/timer/cancel ",
      {}
    );
    return response.data.data;
  } catch (e) {
    console.log(e);
  } finally {
    Vue.prototype.$bus.emit('refreshData')
  }
};


//Cost type
export const fetchCostType = async (uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API + "/v1/costtype/" + uid,
      {}
    );
    state.costType = response.data.data[0];
  } catch (e) {
    console.log(e);
  }
};

export const fetchTaskType = async (uid) => {
  try {
    const response = await Vue.prototype.$axios.get(
      process.env.VUE_APP_ROOT_API + "/v1/tasktype/" + uid,
      {}
    );
    state.taskType = response.data.data[0];
  } catch (e) {
    console.log(e);
  }
};