<template>
  <tr v-if="handleShow" class="dragitem">
    <TaskScheduleTaskHeader
      :index="index"
      :user="user"
      :days="days"
      @toggle="$emit('toggle', $event)"
      :selectedCells="selectedCells"
    />

    <TaskScheduleCell
      v-for="(i, index) in days"
      :key="task.uid + '-' + index"
      :isTimesheetView="isTimesheetView"
      :isTop="isTop"
      :isBottom="isBottom"
      :user="user"
      :task="task"
      @toggleCellMenu="$emit('toggleCellMenu', $event)"
      :index="index"
      :i="i"
      :today="today"
      :yesterday="yesterday"
      @isDragging="$emit('isDragging')"
      :isDragging="isDragging"
      @lastToggle="$emit('updateLastToggle', $event)"
      :selectedCells="selectedCells"
    />
  </tr>
</template>

<script>
import TaskScheduleTaskHeader from "../../TaskSchedule/components/TaskScheduleTaskHeader";
import TaskScheduleCell from "../../TaskSchedule/components/TaskScheduleCell.vue";
export default {
  props: {
    index: {
      type: Number,
    },
    days: {
      type: Object,
    },
    selectedCells: {
      type: Array,
    },
    user: {
      type: Object,
    },
    task: {
      type: Object,
    },
    isDragging: {
      type: Boolean,
    },
    today: {
      type: String,
    },
    yesterday: {
      type: String,
    },
    lastToggle: {
      type: Number,
    },
    isFiltered: {
      type: Boolean,
    },
    isTop: {
      type: Boolean,
    },
    isBottom: {
      type: Boolean,
    },
    isTimesheetView: {
      type: Boolean,
    },
    showUnplanned: {
      type: Boolean,
    },
  },
  components: {
    TaskScheduleTaskHeader,
    TaskScheduleCell,
  },
  computed: {
    expand() {
      return !this.$store.getters.taskScheduleSettings.userCollapse.includes(
        this.user.uid
      );
    },
    hasUnplanned() {
      return this.task.hasPlanned === false;
    },
    handleShow() {
      if (!this.expand) {
        return false;
      }
      if (this.isFiltered) {
        return false;
      }

      if (this.hasUnplanned === true && this.showUnplanned !== true) {
        return false;
      }
      return true;
    },
  },
  methods: {
    checkWorkDay(i) {
      return this.user.working_hours[this.days[i].dayAbbr].on == true;
    },
  },
};
</script>

<style>
</style>